.app-link-button {
    &__href {
        font-family: $avenir-next-weight-400;
        font-size: $text-size-body;
        text-decoration: underline;

        @include max-width($scr-lg) {
            font-size: $text-size-s;
        }
    }

    &__link {
        font-family: $avenir-next-weight-600;
        font-size: $text-size-body;
        text-decoration: underline;

        @include max-width($scr-lg) {
            font-size: $text-size-s;
        }
    }

    &__button {
        font-family: $avenir-next-weight-600;
        font-size: $text-size-body;
        text-decoration: underline;
        cursor: pointer;

        @include max-width($scr-lg) {
            font-size: $text-size-s;
        }
    }

    &__primary {
        color: $c-cyan;
    }

    &__white {
        color: $c-white;
    }

    &--small-font {
        font-size: $text-size-s;
    }
}
