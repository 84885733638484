@font-face {
    font-family: "avenirNext-thin";
    src: local("avenirNext"), url("../assets/fonts/Avenir-Next-Thin.otf") format("opentype");
}

@font-face {
    font-family: "avenirNext-extra-light";
    src: local("avenirNext"), url("../assets/fonts/Avenir-Next-ExtraLight.otf") format("opentype");
}

@font-face {
    font-family: "avenirNext-light";
    src: local("avenirNext"), url("../assets/fonts/Avenir-Next-Light.otf") format("opentype");
}

@font-face {
    font-family: "avenirNext-regular";
    src: local("avenirNext"), url("../assets/fonts/Avenir-Next-Regular.otf") format("opentype");
}

@font-face {
    font-family: "avenirNext-medium";
    src: local("avenirNext"), url("../assets/fonts/Avenir-Next-Medium.otf") format("opentype");
}

@font-face {
    font-family: "avenirNext-semiBold";
    src: local("avenirNext"), url("../assets/fonts/Avenir-Next-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "avenirNext-bold";
    src: local("avenirNext"), url("../assets/fonts/Avenir-Next-Bold.otf") format("opentype");
}

@font-face {
    font-family: "avenirNext-extrabold";
    src: local("avenirNext"), url("../assets/fonts/Avenir-Next-ExtraBold.otf") format("opentype");
}

@font-face {
    font-family: "avenirNext-black";
    src: local("avenirNext"), url("../assets/fonts/Avenir-Next-Black.otf") format("opentype");
}

@font-face {
    font-family: "gotham-bold";
    src: local("avenirNext"), url("../assets/fonts/Gotham-Bold.otf") format("opentype");
}

@font-face {
    font-family: "gotham-book";
    src: local("avenirNext"), url("../assets/fonts/Gotham-Book.otf") format("opentype");
}

@font-face {
    font-family: "gotham-light";
    src: local("avenirNext"), url("../assets/fonts/Gotham-Light.otf") format("opentype");
}

@font-face {
    font-family: "gotham-medium";
    src: local("avenirNext"), url("../assets/fonts/Gotham-Medium.otf") format("opentype");
}

@font-face {
    font-family: "segoe-ui";
    src: local("avenirNext"), url("../assets/fonts/SEGOEUI.TTF") format("truetype");
}
