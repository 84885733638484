.app-react-table {
  &__table-wrapper {
    width: 100%;
    border-spacing: 0;
    border-radius: 10px;
    min-width: 1881.59px;
  }

  .header {
    &__row {
      background-color: $c-light-gray;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: $c-darker-gray;
      font-family: $gotham-weight-400;
      font-size: $text-size-m;
      height: 66px;
      display: flex;
      align-items: center;

      &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:first-child {
          padding-left: 31px;
        }
      }
    }
  }

  .body {
    // @media (min-height: 680px) {
    //   height: 380px;
    // }
    // @media (min-height: 700px) {
    //   height: 400px;
    // }
    // @media (min-height: 750px) {
    //   height: 450px;
    // }
    // @media (min-height: 800px) {
    //   height: 500px;
    // }
    // @media (min-height: 850px) {
    //   height: 550px;
    // }
    // @media (min-height: 900px) {
    //   height: 600px;
    // }
    // @media (min-height: 950px) {
    //   height: 650px;
    // }
    // @media (min-height: 1000px) {
    //   height: 700px;
    // }
    // @media (min-height: 1050px) {
    //   height: 750px;
    // }
    // @media (min-height: 1100px) {
    //   height: 800px;
    // }
    height: 750px;
    display: block;
    overflow: scroll;
    min-width: 1850px;

    &__row {
      color: $c-darker-gray;
      font-size: $text-size-m;
      font-family: $gotham-weight-200;
      background-color: $c-light-gray;
      height: 66px;
      display: flex;

      align-items: center;

      &:nth-child(odd) {
        background-color: $c-table-gray;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &-data {
        // text-align: center;
        &:first-child {
          padding-left: 31px;
        }
        word-break: break-all;

        &_empty-message {
          // word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1871.59px;
          height: 440px;
          font-size: 25px;
          font-family: "gotham-bold";
          color: black;
        }
      }
    }
  }
  @media(max-width:1670px){
    .body__row
    {
      font-size: 17px;
    }
  }

  @media(max-width:1200px){
    .body__row
    {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 910px) {
    .body__row
    {
      font-size: 15px;
    }
  }

  .dashBoardHeader {
    &__row {
      background-color: $c-white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: $c-darker-gray;
      font-family: $gotham-weight-400;
      font-size: $text-size-m;
      height: 40px;
      display: flex;
      align-items: center;

      &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:first-child {
          padding-left: 31px;
        }
      }
    }
  }

  .dashBoardBody {
    // @media(min-height:680px){
    //     height: 380px;
    // }
    // @media(min-height:700px){
    //     height: 400px;
    // }
    // @media(min-height:750px){
    //     height: 450px;
    // }
    // @media(min-height:800px){
    //     height: 500px;
    // }
    // @media(min-height:850px){
    //     height: 550px;
    // }
    // @media(min-height:900px){
    //     height: 200px;
    // }
    // @media(min-height:950px){
    //     height: 200px;
    // }
    // @media(min-height:1000px){
    //     height: 700px;
    // }
    // @media(min-height:1050px){
    //     height: 750px;
    // }
    // @media(min-height:1100px){
    //     height: 800px;
    // }
    height: 145px;

    display: block;
    overflow: scroll;
    background-color: $c-white;
    &__row {
      color: $c-darker-gray;
      font-size: $text-size-m;
      font-family: $gotham-weight-200;
      background-color: $c-white;
      height: 45px;
      display: flex;
      align-items: center;

      &:nth-child(odd) {
        background-color: $c-light-gray;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &-data {
        // text-align: center;
        word-break: break-all;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:first-child {
          padding-left: 31px;
        }
        &_empty-message {
          // word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 713px;
          height: 127px;
          font-size: 18px;
          font-family: "gotham-bold";
          color: black;
        }
      }
    }
  }

  .customerDetailsHeader {
    &__row {
      background-color: $c-white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: $c-darker-gray;
      font-family: $gotham-weight-400;
      font-size: $text-size-m;
      height: 66px;
      display: flex;
      align-items: center;

      &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:first-child {
          padding-left: 31px;
        }
        &:last-child {
          margin-right: 10px;
        }
      }
    }
  }

  .customerDetailsBody {
    // @media(min-height:680px){
    //     height: 380px;
    // }
    // @media(min-height:700px){
    //     height: 400px;
    // }
    // @media(min-height:750px){
    //     height: 450px;
    // }
    // @media(min-height:800px){
    //     height: 500px;
    // }
    // @media(min-height:850px){
    //     height: 550px;
    // }
    // @media(min-height:900px){
    //     height: 200px;
    // }
    // @media(min-height:950px){
    //     height: 200px;
    // }
    // @media(min-height:1000px){
    //     height: 700px;
    // }
    // @media(min-height:1050px){
    //     height: 750px;
    // }
    // @media(min-height:1100px){
    //     height: 800px;
    // }
    height: 380px;
    display: block;
    overflow: scroll;
    &__row {
      color: $c-darker-gray;
      font-size: $text-size-m;
      font-family: $gotham-weight-200;
      background-color: $c-white;
      height: 60px;
      display: flex;
      align-items: center;

      &:nth-child(odd) {
        background-color: $c-light-gray;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &-data {
        // text-align: center;
        word-break: break-all;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:first-child {
          padding-left: 31px;
        }

        &_empty-message {
          // word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1000.83px;
          height: 370px;
          font-size: 25px;
          font-family: "gotham-bold";
          color: black;
        }
        // &:last-child{
        //   margin-right: 31px;
        // }
      }
    }
  }

  .userContractDetailsHeader {
    &__row {
      background-color: $c-table-gray;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: $c-darker-gray;
      font-family: $gotham-weight-400;
      font-size: $text-size-m;
      height: 66px;
      display: flex;
      align-items: center;

      &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:first-child {
          padding-left: 31px;
        }
        &:last-child {
          margin-right: 10px;
        }
      }
    }
  }

  .userContractDetailsBody {
    // @media(min-height:680px){
    //     height: 380px;
    // }
    // @media(min-height:700px){
    //     height: 400px;
    // }
    // @media(min-height:750px){
    //     height: 450px;
    // }
    // @media(min-height:800px){
    //     height: 500px;
    // }
    // @media(min-height:850px){
    //     height: 550px;
    // }
    // @media(min-height:900px){
    //     height: 200px;
    // }
    // @media(min-height:950px){
    //     height: 200px;
    // }
    // @media(min-height:1000px){
    //     height: 700px;
    // }
    // @media(min-height:1050px){
    //     height: 750px;
    // }
    // @media(min-height:1100px){
    //     height: 800px;
    // }
    height: 480px;
    display: block;
    overflow: scroll;
    &__row {
      color: $c-dark-gray;
      font-size: $text-size-m;
      font-family: $gotham-weight-200;
      background-color: $c-table-gray;
      height: 60px;
      display: flex;
      align-items: center;

      &:nth-child(odd) {
        background-color: $c-light-gray;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &-data {
        // text-align: center;
        word-break: break-all;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:first-child {
          padding-left: 31px;
        }

        &_empty-message {
          // word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1780px;
          height: 470px;
          font-size: 25px;
          font-family: "gotham-bold";
          color: black;
        }
      }
    }
  }

  .paymentReturnUploadHeader {
    &__row {
      background-color: $c-white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: $c-darker-gray;
      font-family: $gotham-weight-400;
      font-size: $text-size-m;
      height: 66px;
      display: flex;
      align-items: center;

      &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:first-child {
          padding-left: 31px;
        }
        &:nth-child(5),
        &:nth-child(4) {
          justify-content: center;
        }
      }
    }
  }

  .paymentReturnUploadHeader1 {
    &__row {
      background-color: $c-white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: $c-darker-gray;
      font-family: $gotham-weight-400;
      font-size: $text-size-m;
      height: 66px;
      display: flex;
      align-items: center;

      &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:first-child {
          padding-left: 31px;
        }
        // &:nth-child(5),
        // &:nth-child(4) {
        //   justify-content: center;
        // }
      }
    }
  }

  .paymentReturnUploadBody {
    // @media(min-height:680px){
    //     height: 380px;
    // }
    // @media(min-height:700px){
    //     height: 400px;
    // }
    // @media(min-height:750px){
    //     height: 450px;
    // }
    // @media(min-height:800px){
    //     height: 500px;
    // }
    // @media(min-height:850px){
    //     height: 550px;
    // }
    // @media(min-height:900px){
    //     height: 200px;
    // }
    // @media(min-height:950px){
    //     height: 200px;
    // }
    // @media(min-height:1000px){
    //     height: 700px;
    // }
    // @media(min-height:1050px){
    //     height: 750px;
    // }
    // @media(min-height:1100px){
    //     height: 800px;
    // }
    height: 380px;
    display: block;
    overflow: scroll;
    &__row {
      color: $c-darker-gray;
      font-size: $text-size-m;
      font-family: $gotham-weight-200;
      background-color: $c-white;
      height: 60px;
      display: flex;
      align-items: center;

      &:nth-child(odd) {
        background-color: $c-table-gray;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &-data {
        // text-align: center;
        word-break: break-all;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:first-child {
          padding-left: 31px;
        }

        &:nth-child(5),
        &:nth-child(4) {
          justify-content: center;
        }

        &_empty-message {
          // word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1199px;
          height: 370px;
          font-size: 25px;
          font-family: "gotham-bold";
          color: black;
        }
      }
    }
  }

  .invoiceHistoryHeader {
    &__row {
      background-color: $c-white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: $c-darker-gray;
      font-family: $gotham-weight-400;
      font-size: $text-size-s;
      height: 66px;
      display: flex;
      align-items: center;
      max-width: 932px;

      &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &:first-child {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:last-child {
          margin-right: 10px;
        }
      }
    }
  }

  .invoiceHistoryBody {
    // @media(min-height:680px){
    //     height: 380px;
    // }
    // @media(min-height:700px){
    //     height: 400px;
    // }
    // @media(min-height:750px){
    //     height: 450px;
    // }
    // @media(min-height:800px){
    //     height: 500px;
    // }
    // @media(min-height:850px){
    //     height: 550px;
    // }
    // @media(min-height:900px){
    //     height: 200px;
    // }
    // @media(min-height:950px){
    //     height: 200px;
    // }
    // @media(min-height:1000px){
    //     height: 700px;
    // }
    // @media(min-height:1050px){
    //     height: 750px;
    // }
    // @media(min-height:1100px){
    //     height: 800px;
    // }
    height: 530px;
    display: block;
    overflow: scroll;

    &__strike {
      width: inherit;

      //content: url("../../assets/images/components/strike.png");
      background-repeat: repeat-x;
      background-position: 50% 50%;
      z-index: 100;
      max-width: 932px;
      position: absolute;
    }
    &__text-red {
      color: red;
    }
    &__row {
      color: $c-darker-gray;
      font-size: $text-size-s;
      font-family: $gotham-weight-200;
      background-color: $c-white;
      height: 60px;
      display: flex;
      z-index: 1;
      justify-content: center;
      align-items: center;
      max-width: 932px;

      &:nth-child(odd) {
        background-color: $c-light-gray;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &-data {
        // text-align: center;
        word-break: break-all;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        &:first-child {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &_empty-message {
          // word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 922px;
          height: 520px;
          font-size: 25px;
          font-family: "gotham-bold";
          color: black;
          max-width: 932px;
        }
        // &:last-child{
        //   margin-right: 31px;
        // }
      }
    }
  }

  .body::-webkit-scrollbar,
  .dashBoardBody::-webkit-scrollbar,
  .customerDetailsBody::-webkit-scrollbar,
  .paymentReturnUploadBody::-webkit-scrollbar,
  .userContractDetailsBody::-webkit-scrollbar,
  .invoiceHistoryBody::-webkit-scrollbar {
    // display: none;
    width: 10px;
    height: 10px;
  }
  .body::-webkit-scrollbar-track,
  .dashBoardBody::-webkit-scrollbar-track,
  .customerDetailsBody::-webkit-scrollbar-track,
  .paymentReturnUploadBody::-webkit-scrollbar-track,
  .userContractDetailsBody::-webkit-scrollbar-track,
  .invoiceHistoryBody::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  .body::-webkit-scrollbar-thumb,
  .dashBoardBody::-webkit-scrollbar-thumb,
  .customerDetailsBody::-webkit-scrollbar-thumb,
  .paymentReturnUploadBody::-webkit-scrollbar-thumb,
  .userContractDetailsBody::-webkit-scrollbar-thumb,
  .invoiceHistoryBody::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }
}
