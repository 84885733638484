.app-auth-layout {
    height: 100%;
    min-height: 640px;
    background-image: url("../../../assets/images/pages/authentication/page-auth-background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;

    &__body {
        width: 50%;
        flex-direction: column;
        align-items: center;
        display: flex;
        justify-content: center;

        @include max-width($scr-md) {
            margin: auto;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        .version-text {
            color: $c-white;
            font-size: 25px;
            font-family: "gotham-bold";
            position: relative;
            top: 95px;
            right: 220px;
        }

        @media (max-width: $scr-md) {
            .version-text {
                color: $c-white;
                font-size: 25px;
                font-family: "gotham-bold";
                position: relative;
                top: 95px;
                right: 0px;
            }
        }
    }

    &__content {
        min-width: 430px;

        .header {
            align-items: center;
            display: flex;
            flex-direction: column;

            &__logo {
                height: 200px;
                width: 500px;
                object-fit: contain;
                margin: 30px 0px;
            }

            &__title {
                font-family: "Oswald";
                font-weight: $text-weight-bold;
                font-size: $text-size-xxxxl;
                color: $c-white;

                @include max-width($scr-lg) {
                    font-size: $text-size-xxl;
                }
            }

            &__description {
                font-family: $avenir-next-weight-400;
                font-size: $text-size-m;
                color: $c-body;
                text-align: center;

                @include max-width($scr-lg) {
                    font-size: $text-size-body;
                }
            }
        }

        .footer {
            font-family: $avenir-next-weight-600;
            font-size: $text-size-s;
            color: $c-body;
            margin-top: 40px;
            display: flex;
            justify-content: center;

            @include max-width($scr-lg) {
                font-size: $text-size-xs;
            }

            &__link {
                font-size: $text-size-s;

                @include max-width($scr-lg) {
                    font-size: $text-size-xs;
                }
            }
        }
    }
}
