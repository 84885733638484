.page-loan-application-list {
    zoom: 80%;
    height: 100%;
    padding: 5px 15px 0px 15px;
    background-color: #FFFFFF;
    width: 100%;

    .fixed-content{
        // width: 98%;
        // position: fixed;
        min-width: 1881.59px;
    }
    .top-context {
        height: 90px;
    }
    .title {
        float: left;
        font-size: 60px;
        color: $c-beige;
        font-family: "gotham-bold";
        letter-spacing: 0;
        text-align: left;
    }
    .search-bar {
        width:400px;
        float: left;
        padding-top: 30px;
        margin-left: 20px;
    }
    .search-button {
        width: 200px;
        float: right;
        padding-right: 10px;
        height: 50px;
        margin-top: 20px;
    }
    .input-field {
        border: #E3E3E3 1px solid;
        border-radius: 10px;
        width: 205px;
        height: 37px;
        text-align: center;
    }
    .pagination-button {
        display: inline-block;
        margin-bottom: 10px;
        float: right;
        span {
            font-family: "gotham-bold";
        }
        button{
            text-decoration: none;
            transition: background-color .3;
            border: none;
            background-color: transparent;
        }
        button:hover{
            background-color: #ddd;
        }
    }
    &__react-table-wrapper {
        .react-table {
            
            &__icon {
                position: absolute;
                top: 9px;
                left: 5px;
                width: 17px;
                height: 17px;
                border: solid $c-table-icon;
                border-width: 5px 0px 0px 5px;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &__icon-wrapper {
                position: relative;
                border: 3.5px solid $c-table-icon;
                border-radius: 9px;
                margin-left: 8px;
                color: $c-table-icon;
                height: 35px;
                width: 35px;
                align-items: center;
                justify-content: center;
                display: none;

                &-down {
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }

                &--show {
                    display: inline-block;
                }
            }

            &__header {
                display: flex;
                align-items: center;

                &-title {
                    text-decoration: underline;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            &__search-button {
                border: none;
                color: $c-white;
                width: 60px;
                height: 30px;
                background-color: $c-orange;
                border-radius: 10px;
            }

            &__icon-button {
                border: none;
                color: $c-white;
                width: 60px;
                height: 38px;
                background-color: $c-orange;
                border-radius: 10px;
            }

            &__search-button-disable {
                border: none;
                color: $c-white;
                width: 60px;
                height: 30px;
                background-color: $c-bclg-gray;
                border-radius: 10px;
                cursor: default;
            }
        }
    }
}
