.contract-view {
  zoom: 80%;
  height: 100%;
  padding: 5px 15px 0px 15px;
  background-color: #ffffff;
  margin-bottom: 50px;
  min-width: $min-width;
  .fixed-content {
    width: 98%;
    position: fixed;
  }
  .top-context {
    // height: 90px;
    padding: 0px 50px 0px 50px;
    .tabs {
      height: 100px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-end;
      .tablinks {
        min-width: 230px;
        height: 80px;
        float: left;
        font-family: "gotham-bold";
        font-size: x-large;
        text-align: center;
        color: white;
        border-radius: 10px 10px 0px 0px;
        padding: 22px 0px;
        cursor: pointer;
      }
      .tab-selected {
        background-color: #f6f6f6;
        color: #a28559;
      }
      .tab1 {
        background-color: #856e49;
      }
      .tab2 {
        background-color: #a28559;
      }
      .tab3 {
        background-color: #dcc7aa;
      }
    }
    .dropdown {
      position: relative;
      display: inline-block;
    }

    .dropSpan {
      font-size: 25px;
      cursor: pointer;
      font-family: "gotham-medium";
    }

    .dropButton {
      background-color: #f5903d;
      color: white;
      border: none;
      border-radius: 10px;
      width: 190px;
      height: 50px;
      font-size: 16px;
      cursor: pointer;
      font-family: "gotham-medium";
    }

    .dropButtonHover {
      background-color: white;
      color: #f5903d;
      border: none;
      border-radius: 10px;
      width: 190px;
      height: 50px;
      font-size: 16px;
      cursor: pointer;
      font-family: "gotham-medium";
      text-decoration: underline;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      font-size: 18px;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      text-align: center;
      border-radius: 15px;
      left: 50% !important;
      right: auto !important;
      transform: translate(-50%, 0) !important;
      span {
        border-bottom: 2px solid #9d9d9c;
        padding: 1px 20px;
        color: $c-beige;
        font-family: "gotham-medium";
        font-size: 20px;
      }
    }

    .dropdown-content a {
      color: #9d9d9c;
      padding: 3px 4px;
      text-decoration: none;
      display: block;
    }

    .dropdown-content a:hover {
      background-color: #f1f1f1;
      border-radius: 15px;
    }

    .show {
      display: block;
    }
  }
  .title {
    float: left;
    font-size: 60px;
    color: $c-beige;
    font-family: "gotham-bold";
    letter-spacing: 0;
    text-align: left;
  }
  .search-button {
    width: 200px;
    float: right;
    height: 50px;
    // padding-right: 10px;
    // margin-top: 20px;
  }

  .search-button1 {
    height: 50px;
    button{
      border: none;
      border-radius: 10px;
      color: $c-white;
      background-color: $c-orange;
      height: 100%;
      width: 450px;
      font-family: "gotham-bold";
      font-size: 22px;
    }
  }

  .contract-detail {
    background-color: #f6f6f6;
    margin: 0px 50px 30px 50px;
    .wrapper {
      display: grid;
      // grid-template-areas:
      //   "review"
      //   "ctosScore";
      grid-template-columns: 45% auto;
      grid-template-areas: "review" "ctosScore";
    }
    .review {
      grid-area: auto;
      padding: 25px 10px 20px 35px;
      .assigned {
        font-family: "gotham-bold";
        color: $c-beige;
        font-size: x-large;
      }
      .reviewing {
        padding-top: 8px;
        font-family: "gotham-book";
        font-size: medium;
        float: right;
      }
      .largeText {
        font-family: "gotham-bold";
        font-size: 38px;
        color: #9d9d9c;
      }
      hr {
        border-top: 3px solid #9d9d9c;
      }
      .content {
        margin-bottom: 20px;
        height: 40px;
        span {
          font-family: "gotham-bold";
          color: #1d1d1d;
          font-size: 22px;
          width: 40%;
          float: left;
        }
        input {
          border: none;
          border-radius: 5px;
          height: 46px;
          margin-right: 10px;
          width: 58%;
          float: left;
          padding-left: 10px;
        }
        textarea {
          padding-left: 10px;
          border: none;
        }
      }
      .item1 {
        grid-area: text;
        font-size: 30px;
        font-family: "gotham-bold";
        width:452px;    
      }
      .item2 {
        grid-area: label;
        font-size: 30px;
        font-family: "gotham-book";
      }
      .item3 {
        grid-area: icon;
        min-width:154px;
        img {
          width: 83.74px;
          float: right;
          cursor: pointer;
        }
        label {
          width: 83.74px;
          float: right;
          cursor: pointer;
        }
      }
      .item4 {
        grid-area: nexticon;
        img {
          width: 83.74px;
        }
      }
      .defaultPointer{
        filter: alpha(opacity=40);
        opacity: 0.4;
        cursor: not-allowed;

      }
      .pointer {
        cursor: pointer;
      }
      .item5 {
        grid-area: icon;
        img {
          width: 50px;
        }
      }
      .disable-img {
        filter: alpha(opacity=40);
        opacity: 0.4;
        cursor: not-allowed;
      }
      .grid-container {
        display: grid;
        grid-template:
          "text text icon nexticon"
          "label label icon nexticon";
        grid-gap: 1px;
        padding: 1px;
        margin-bottom: 20px;
        // img{
        //     cursor: pointer;
        // }
      }
      .payment-content {
        margin-bottom: 20px;
        height: 40px;
        span {
          font-family: "gotham-bold";
          color: #1d1d1d;
          font-size: 22px;
          width: 59%;
          float: left;
        }
        input {
          border: none;
          border-radius: 5px;
          height: 46px;
          margin-right: 10px;
          width: 39%;
          float: left;
          padding-left: 20px;
        }
        .redButton {
          border: none;
          border-radius: 8px;
          background-color: #fc1818;
          color: #ffffff;
          font-family: "gotham-bold";
          font-size: 22px;
          width: 46px;
          height: 46px;
          position: absolute;
          // left: 767px;
        }
      }
      .bottom-content {
        div {
          margin-bottom: 15px;
        }
        span {
          font-family: "gotham-bold";
          color: #1d1d1d;
          font-size: 18px;
          width: 25%;
        }
        input {
          border-radius: 5px;
          height: 32px;
          margin-right: 10px;
          width: 70%;
          float: right;
        }
        .remark {
          width: 71.3%;
          float: right;
          select {
            width: 88%;
            border-radius: 5px;
            height: 32px;
          }
          textarea {
            border-radius: 5px;
          }
          button {
            background-color: $c-orange;
            color: white;
            width: 10%;
            height: 32px;
            border: none;
            border-radius: 8px;
            margin-right: 10px;
          }
        }
      }
    }
    .ctosScore {
      grid-area: auto;
      .tab {
        overflow: hidden;
        border: 1px solid #ccc;
        background-color: #f1f1f1;
      }

      /* Style the buttons inside the tab */
      .tab button {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        font-size: 17px;
      }

      /* Change background color of buttons on hover */
      .tab button:hover {
        background-color: #ddd;
      }

      /* Create an active/current tablink class */
      .tab button.active {
        background-color: #ccc;
      }

      /* Style the tab content */
      .tabcontent {
        display: none;
        padding: 6px 12px;
        border: 1px solid #ccc;
        border-top: none;
      }
    }
    // @media (min-width: 1000px) {
    //   .wrapper {
    //     grid-template-columns: 45% auto;
    //     grid-template-areas: "review" "ctosScore";
    //   }
    // }

    // @media (max-width: 1253px) {
    //   .review .largeText {
    //     font-family: "gotham-bold";
    //     font-size: large;
    //     color: #9d9d9c;
    //     float: right;
    //   }
    //   .review .select-field {
    //     margin-top: 8px;
    //     border: #e3e3e3 1px solid;
    //     border-radius: 8px;
    //     width: 200px;
    //     height: 30px;
    //     text-align: center;
    //   }
    //   .review .bottom-content span {
    //     width: 100%;
    //   }
    //   .review .bottom-content input {
    //     width: 100%;
    //   }
    //   .review .bottom-content .remark {
    //     width: 100%;
    //   }
    //   .review .bottom-content .remark select {
    //     float: left;
    //     width: 100%;
    //   }
    // }

    .select-field {
      margin-top: 8px;
      border: #e3e3e3 1px solid;
      border-radius: 8px;
      width: 250px;
      height: 37px;
      text-align: center;
    }

    .display {
      display: contents;
    }

    .hide {
      display: none;
    }

    .loan-application-view {
      &__score {
        &-wrapper {
          margin: 40px 10px 40px 10px;
          border-radius: 10px;
          min-width: 600px;
        }
        &-button-row {
          display: flex;
          flex-direction: row;
          height: 120px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        &-button {
          display: flex;
          flex: 1;
          background-color: #a28559;
          border-radius: 10px;
          &:hover {
            cursor: pointer;
          }
          &--darker {
            background-color: #856e49 !important;
          }
          &--active {
            background-color: white !important;
          }
        }
        &-label-column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 3;
          padding-left: 10px;
        }

        &-label-title {
          color: $c-white;
          font-size: 34px;
          font-family: $gotham-weight-400;
          justify-content: center;
          align-items: center;
          display: flex;
          &--active {
            color: $c-maire !important;
          }
        }

        &-label-subtitle {
          font-size: 22px;
          color: $c-white;
          justify-content: center;
          align-items: center;
          display: flex;
          &--active {
            color: #9d9d9c !important;
          }
        }

        &-label-text {
          &--active {
            color: white;
          }
        }

        &-graph {
          position: relative;
          padding: 5px;
          &-wrapper {
            flex: 1;
            display: flex;
            align-items: center;
          }
        }

        &-point {
          position: absolute;
          height: 90%;
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $gotham-weight-300;
          color: $c-white;
          &--active {
            color: $c-black;
          }
        }

        &-content {
          background-color: $c-white;
          margin-top: -10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          &-wrapper {
            height: 460px;
            padding: 30px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          &-row {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;
            color: $c-lg-gray;
          }

          &-column {
            &-1 {
              display: flex;
              flex: 3;
              font-family: $gotham-weight-400;
              font-size: $text-size-l;
            }
            &-2 {
              display: flex;
              flex: 3;
              font-family: $gotham-weight-200;
              font-size: $text-size-l;
            }
            &-3 {
              display: flex;
              flex: 1;
              font-family: $gotham-weight-200;
              font-size: $text-size-l;
            }
          }
        }

        &-body {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          &:last-child {
            margin-bottom: none;
          }
          &-title {
            color: $c-lg-gray;
            font-family: $gotham-weight-400;
            font-size: $text-size-l;
          }
          &-detail {
            color: $c-lg-gray;
            font-family: $gotham-weight-200;
            font-size: 20px;
          }
        }

        &-ctos-report {
          color: #437fec;
          font-size: 20px;
          font-family: $gotham-weight-200;
          text-decoration: none;
        }
      }

      &__video {
        &-wrapper {
          margin-top: 25px;
          display: none;
          &--show {
            display: block;
          }
        }

        &-none {
          font-family: $gotham-weight-400;
        }

        &-tab-row {
          display: flex;
          flex-direction: row;
        }

        &-tab-button {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          background-color: #856e49;
          font-family: $gotham-weight-400;
          color: $c-white;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          border-top: 1px solid #856e49;
          border-left: 1px solid #856e49;
          border-right: 1px solid #856e49;
          font-size: x-large;
          &--active {
            background-color: $c-white;
            color: #856e49;
          }
          &:hover {
            cursor: pointer;
          }
        }

        &-contents {
          background-color: $c-white;
          // height: 400px;
          border-bottom: 1px solid $c-beige;
          border-left: 1px solid $c-beige;
          border-right: 1px solid $c-beige;
        }

        &-body {
          &--hide {
            display: none;
          }
          &--show {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
        }
      }

      &__download-wrapper {
        border: 1px solid $c-table-icon;
        padding: 0px 5px;
        border-radius: 6px;
        margin-right: 10px;
        cursor: pointer;
        color: $c-table-icon;
      }
    }
  }

  .pagination-button {
    display: inline-block;
    margin-top: 10px;
    float: right;
    span {
      font-family: "gotham-bold";
    }
    button {
      text-decoration: none;
      transition: background-color 0.3;
      border: none;
      background-color: transparent;
    }
    button:hover {
      background-color: #ddd;
    }
  }

  .table-border {
    background-color: #ffffff;
    // padding: 1px 10px 0px 10px;
    // border-style: solid;
    // border-color: #A28559;
    border-top: #ffffff;
    border-radius: 10px;
    width:950px;
  }
}
