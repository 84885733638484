.page-initial-approval-matrix {
  zoom: 85%;
  background-color: $c-white;
  height: 100%;

  padding: 5px 5% 5px 5%;
  min-width: 1000px;
  width: 100%;
  margin: auto;

  .initial-approval-matrix {
    &__top-content {
      width: 100%;
      margin: auto;
      padding: 0px 0px 0px 0px;
      min-width: 1763px;
      .title {
        color: $c-beige;
        font-size: 60px;
        font-family: "gotham-bold";
        padding: 0px 0px 0px 0px;
      }

      .update-button {
        width: 250px;
        font-size: 35px;
        border-radius: 10px;
        border: none;
      }

      .button {
        margin: auto;
        text-align: center;
      }
      .c-orange {
        background-color: $c-orange;
        color: $c-white;
      }
      .w-bold {
        font-family: "gotham-bold";
        font-size: 25px;
      }

      .card-border {
        border-color: none;
        background: transparent;
      }
      .border-success {
        border: none;
      }

      .card {
        border: none;
      }

      .card-header {
        border-radius: 10px 10px 0px 0px;
      }

      .card-footer {
        border-radius: 0px 0px 10px 10px;
      }

      .img {
        background-color: white;
      }

      .pop-up-alert-content {
        position: center;
        // padding: 10px 63px 10px 63px;
        width: fit-content;
        height: fit-content;
        background-color: white;
        border-radius: 10px;
        text-align: center;
        font-family: "gotham-bold";
        font-size: 25px;
      }

      .msg-box {
        width: 400px;
        height: fit-content;
        // height: 25px;
      }
      .pop-up-alert {
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .c-beige {
        background-color: $c-beige;
        color: $c-white;
      }

      .card-body-font {
        color: $c-beige;
      }

      .card-body {
        height: 185px;
        font-size: 20px;
      }

      .card-title {
        height: 100px;
        width: 100px;
      }

      img[alt]:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        margin: auto;
        text-align: center;
        content: attr(alt);
      }
    }

    &__approval-box {
      background-color: $c-body;
      height: fit-content;
      border-radius: 10px;
      min-width: 1763px;
      // width: 100%;

      .update-button {
        width: 250px;
        font-size: 35px;
        border-radius: 10px;
        border: none;
      }

      .c-orange {
        background-color: $c-orange;
        color: $c-white;
      }

      .c-beige {
        background-color: $c-beige;
        color: $c-white;
      }

      .c-star-dust {
        background-color: $c-star-dust;
        color: $c-white;
        border-radius: 10px 0 0px 10px;
      }

      .w-light {
        font-family: "gotham-light";
        font-size: 28px;
      }

      .w-medium {
        font-family: "gotham-medium";
        font-size: 35px;
      }

      .w-bold {
        font-family: "gotham-bold";
        font-size: 30px;
      }
      .approval-content-title {
        border-radius: 10px 10px 0px 0px;

        background-color: $c-beige;
        padding-top: 15px;
        padding-bottom: 15px;

        align-content: center;
      }

      .error,
      .null {
        font-size: 10px;
      }

      .over-scrolling-input {
        width: 79px;
        padding: 0px;
      }

      .overall-scoring,
      .key-criteria {
        border-right: 3px solid $c-beige;
        align-items: center;
        padding-left: 35px;
        padding-top: 20px;
        font-family: "gotham-bold";
        font-size: 20px;
        margin-bottom: 30px;
      }

      .ivc-scorecard {
        padding-top: 20px;
        padding-left: 35px;
        padding-right: 35px;
      }

      .ctos-score {
        // margin: 10px 0px;
        // display: grid;
        // grid-template-columns: 60% 30%;
        text-align: center;
      }

      .min-max-score {
        width: 100%;
        // margin: auto;
        margin: auto;
        padding-bottom: 0px;
        // background-color: yellow;
        font-size: 20px;
      }

      .mandatory,
      .check-box-key-criteria > div > span,
      div.check-box > span {
        padding-left: 10px;
      }

      .mandatory-words > div,
      .check-box-key-criteria > div,
      .ivc-scorecard-group-content > div.check-box {
        display: flex;
        align-items: center;
        margin-top: 8px;
      }

      .input-score {
        font-family: "gotham-bold";
        font-size: 36px;
        margin: auto;
      }

      .total-ctos-score {
        font-family: "gotham-book";
        font-size: 30px;
        margin: 0;
        text-align: center;
        padding-top: 10px;
      }

      span.input-age {
        float: right;
        color: red;
      }

      .ivc-scorecard-group-title {
        font-family: "gotham-bold";
        font-size: 20px;
      }

      .ivc-scorecard-group-content {
        font-family: "gotham-book";
        padding-left: 10px;
        font-size: 20px;
        border: 2px solid $c-body;
      }

      .white-box {
        background-color: $c-white;
        color: black;
        margin-left: 20px;
        font-size: 20px;
        font-family: "gotham-bold";
        border: 2px solid $c-body;
        // width:193.94px;
        height: 34px;
        //   /  width:100%;
      }

      .s-white-box {
        background-color: $c-white;
        color: black;
        margin-left: 20px;
        font-size: 20px;
        font-family: "gotham-bold";
        border: 2px solid $c-body;
        // width: 335px;
        height: 34px;
      }

      // @media only screen and (max-device-width: 1200px) {
      //   .s-white-box {
      //     background-color: $c-white;
      //     color: black;
      //     margin-left: 20px;
      //     font-size: 20px;
      //     font-family: "gotham-bold";
      //     border: 2px solid $c-body;
      //     width: 412px;
      //     height: 34px;
      //   }
      //   .min-max-score {
      //     font-size: 12px;
      //   }
      //   .w-light {
      //     font-family: "gotham-light";
      //     font-size: 13px;
      //   }
      //   .overall-scoring {
      //     align-items: center;
      //     padding-left: 20px;
      //     padding-top: 15px;
      //     font-size: 13px;
      //   }

      //   .c-star-dust {
      //     background-color: $c-star-dust;
      //     color: $c-white;
      //     border-radius: 10px 0 0px 10px;
      //     padding: 0px 5px 0px 5px;
      //   }

      //   .over-scrolling-input {
      //     width: 47px;
      //     padding: 0px;
      //   }

      //   .total-ctos-score {
      //     font-family: "gotham-book";
      //     font-size: 13px;
      //     margin: 0;
      //     text-align: center;
      //     padding-top: 10px;
      //     padding-left: 5px;
      //   }

      //   .error,
      //   .null {
      //     font-size: 6px;
      //     padding: 0px;
      //   }
      // }

      // @media only screen and (max-device-width: 1024px) {
      //   .over-scrolling-input {
      //     width: 39px;
      //   }

      //   .error,
      //   .null {
      //     font-size: 5.5px;
      //   }
      //   .s-white-box {
      //     background-color: $c-white;
      //     color: black;
      //     margin-left: 20px;
      //     font-size: 20px;
      //     font-family: "gotham-bold";
      //     border: 2px solid $c-body;
      //     width: 355px;
      //     height: 34px;
      //   }
      // }

      .ivc-scorecard-select-area {
        background-color: $c-nenon-green;
        color: $c-white;
        font-size: 18px;
        font-family: "gotham-bold";
        text-align: center;
        padding: 0px;
        border-top: 2px solid $c-body;
        border-bottom: 2px solid $c-body;
        //  margin: auto 0;
        border-left: none;
        border-right: none;
        height: 34px;
        // width:100%;
      }

      .ivc-scorecard-group-results > div {
        background-color: $c-white;
        text-align: center;
        border: 2px solid $c-body;
        border-radius: 10px;
      }

      .ivc-scorecard-group-results > div.gray-box {
        background-color: $c-body;
        // color: $c-body;
        // font-size: 20px;
        width: fit-content;
        height: 34px;
      }

      .ivc-scorecard-group-results {
        font-family: "gotham-bold";
        font-size: 20px;
      }

      div.container-gap {
        margin-bottom: 10px;
      }

      .dsr {
        background-color: $c-body;
        text-align: center;
        border: 2px solid $c-body;
        margin: 20px 0px 20px 20px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
      .margin-input-dsr{
        margin-right: 10px;
      }

      .ivc-scorecard-group-content > div.check-box {
        font-size: 15px;
        font-family: "gothan-book";
        color: $c-lg-gray;
        padding-left: 20px;
      }

      .dsr-word {
        color: black;
        font-family: "gotham-bold";
        font-size: 20px;
        padding: 0px;
      }

      .ivc-scorecard-group-results > div.spacing-dsr {
        margin: 20px 0px 20px 20px;
        color: $c-body;
        background-color: $c-body;
      }

      .ivc-scorecard-group-results > div.gray-box-2 {
        width: fit-content;
        height: 22.5px;
      }

      .check-box-key-criteria > div {
        color: black;
        font-size: 20px;
        font-family: "gotham-book";
      }

      .key-criteria-instruction {
        color: $c-beige;
      }

      .pop-up {
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      // @media only screen and (max-device-width: 1200px) {
      //   .pop-up {
      //     position: fixed;
      //     z-index: 100;
      //     top: 0;
      //     left: 0;
      //     bottom: 0;
      //     right: 0;
      //     width: 100%;
      //     height: 100%;
      //     background-color: rgba(0, 0, 0, 0.2);
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //   }

      //   .pop-up-data {
      //     position: center;
      //     width: 52% !important;
      //     background-color: white;
      //     border-radius: 30px;
      //     text-align: center;
      //     font-family: "gotham-bold";
      //     font-size: 25px;

      //     padding: 10px 10px 10px 53px !important;
      //   }

      //   @media only screen and (max-device-width: 1024px) {
      //     .pop-up-data {
      //       position: center;
      //       width: 62% !important;
      //       background-color: white;
      //       border-radius: 30px;
      //       text-align: center;
      //       font-family: "gotham-bold";
      //       font-size: 25px;

      //       padding: 10px 10px 10px 53px !important;
      //     }
      //   }
      //   .area-container {
      //     border: 1px solid black;
      //     width: 250px;
      //     border-radius: 4.49px;
      //     cursor: pointer;
      //   }
      // }

      .pop-up-data {
        position: center;
        padding: 10px 63px 10px 63px;
        width: 62%;
        background-color: white;
        border-radius: 30px;
        text-align: center;
        font-family: "gotham-bold";
        font-size: 25px;
        min-width:1175.52px;
      }

      .area-container {
        border: 1px solid black;
        width: 250px;
        border-radius: 4.49px;
        cursor: pointer;
      }

      .selected-area-container {
        background-color: white;
        color: $c-orange;
        border-color: $c-orange;
      }

      .exist-area-container {
        background-color: $c-blg-gray;
        color: $c-bclg-gray;
        border: none;
        cursor: unset;
      }
    }
  }
}
