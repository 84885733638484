.app-checkbox {
    &__container {
        display: block;
        position: relative;
        padding-left: 25px;
        margin-top: 5px;
        cursor: pointer;
        font-size: 22px;
        user-select: none;

        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            /* When the checkbox is checked, add a blue background */
            &:checked ~ .app-checkbox__checkmark {
                background-color: $c-cyan;

                /* Show the checkmark when checked */
                &:after {
                    display: block;
                }
            }
        }

        /* On mouse-over, add a grey background color */
        &:hover input ~ .app-checkbox__checkmark {
            background-color: $c-weathered-stone;
        }
    }

    /* Create a custom checkbox */
    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: $c-body;
        border-radius: 2px;

        /* Create the checkmark/indicator (hidden when not checked) */
        &:after {
            content: "";
            position: absolute;
            display: none;

            /* Style the checkmark/indicator */
            left: 5px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: 1px solid $c-white;
            border-width: 0px 3px 3px 0px;
            transform: rotate(45deg);
        }

        &--error {
            outline: 1px solid $c-red;
            outline-offset: 2px;
        }
    }

    &__table-checkbox {
        border-radius: 99px;
        height: 18px;
        width: 18px;
        margin-top: -10px;
        &:after {
            left: 7px;
            top: 4px;
        }
    }
}
