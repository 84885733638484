.merchant-view {
    zoom: 80%;
    height: 100%;
    padding: 5px 15px 0px 15px;
    background-color: #FFFFFF;
    margin-bottom: 50px;
    // min-width: $min-width;
    .fixed-content{
        width: 98%;
        position: fixed;
    }
    .top-context {
        // height: 90px;
        padding: 0px 50px 0px 50px;
        .tabs {
            height: 100px;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-end;
            justify-content: space-between;
            cursor: pointer;
            .tablinks {
                min-width: 230px;
                height: 80px;
                float: left;
                font-family: "gotham-bold";
                font-size: x-large;
                text-align: center;
                color: white;
                border-radius: 10px 10px 0px 0px;
                padding: 22px 0px;
            }
            .discover-tablinks {
                min-width: 330px;
                height: 80px;
                float: left;
                font-family: "gotham-bold";
                font-size: x-large;
                text-align: center;
                color: white;
                border-radius: 10px 10px 0px 0px;
                padding: 22px 0px;
            }
            .tab-selected {
                background-color: #F6F6F6;
                color: #A28559;
            }
            .tab1 {
                background-color: #856E49;
            }
            .tab2 {
                background-color: #DCC7AA;
            }
            .tab3 {
                background-color: #DCC7AA;
            }
            .tab4 {
                background-color: #A28559;
            }
        }
        .dropdown {
            position: relative;
            display: inline-block;
        }
    
        .dropSpan {
            font-size: 25px;
            cursor: pointer;
            font-family: "gotham-medium";
        }

        .dropButton {
            background-color: #F5903D;
            color: white;
            border: none;
            border-radius: 10px;
            width: 190px;
            height: 50px;
            font-size: 16px;
            cursor: pointer;
            font-family: "gotham-medium";
        }

        .dropButtonHover {
            background-color: white;
            color: #F5903D;
            border: none;
            border-radius: 10px;
            width: 190px;
            height: 50px;
            font-size: 16px;
            cursor: pointer;
            font-family: "gotham-medium";
            text-decoration: underline;
        }
    
        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f1f1f1;
            font-size: 18px;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
            text-align: center;
            border-radius: 15px;
            left: 50% !important;
            right: auto !important;
            transform: translate(-50%,0) !important;
            span{
                    border-bottom: 2px solid #9D9D9C;
                    padding: 1px 20px;
                    color: $c-beige;
                    font-family: "gotham-medium";
                    font-size: 20px;
            }
        }
    
        .dropdown-content a {
            color: #9D9D9C;
            padding: 3px 4px;
            text-decoration: none;
            display: block;
        }
    
        .dropdown-content a:hover {
            background-color: #f1f1f1;
            border-radius: 15px;
        }
    
        .show {display: block;}
    }
    .title {
        float: left;
        font-size: 60px;
        color: $c-beige;
        font-family: "gotham-bold";
        letter-spacing: 0;
        text-align: left;
    }
    .search-bar {
        width:400px;
        float: left;
        // padding-top: 30px;
        margin-right: 20px;
    }
    .search-button {
        width: 200px;
        float: right;
        height: 50px;
        // padding-right: 10px;
        // margin-top: 20px;
    }

    .check-box {
        zoom: 1.5;
        transform: scale(1.5);
        -ms-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        -moz-transform: scale(1.5);
        transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
    }

    .merchant-detail {
        background-color: #F6F6F6;
        margin: 0px 50px 30px 50px;
        padding: 20px 20px 60px 20px;

     

        .display {
            display: contents;
        }

        .hide {
            display: none;
        }

        .save-pdf {
            border: none;
            border-radius: 5px;
            color: #FFFFFF;
            background-color: #9D9D9C;
            width: 300px;
            height: 50px;
        }

        .terminate-btn {
            border: none;
            border-radius: 5px;
            color: #FFFFFF;
            background-color: #FC1818;
            width: 100%;
            height: 40px;
            font-size: 22px;
            font-family: "gotham-bold";
        }

        .res-data {
            height: 37.5px;
            background-color: #FFFFFF;
            font-size: 25px;
            font-family: "gotham-book";
        }

        .radio-check {
            transform: scale(2); 
        }
    }

    .merchant-detail-2 {
        background-color: #F6F6F6;
        margin: 0px 50px 30px 50px;
        padding: 20px 0px 20px 0px;

        .display {
            display: contents;
        }

        .hide {
            display: none;
        }

        .save-pdf {
            border: none;
            border-radius: 5px;
            color: #FFFFFF;
            background-color: #9D9D9C;
            width: 300px;
            height: 50px;
        }

        .terminate-btn {
            border: none;
            border-radius: 5px;
            color: #FFFFFF;
            background-color: #FC1818;
            width: 100%;
            height: 40px;
            font-size: 22px;
            font-family: "gotham-bold";
        }

        .res-data {
            height: 37.5px;
            background-color: #FFFFFF;
            font-size: 25px;
            font-family: "gotham-book";
        }

        .radio-check {
            transform: scale(2); 
        }
    }

    .select-input {
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-family: "gotham-book";
        padding: 10px 0px 0px 13px;
        border: 1px solid #d9d9d9;
    }

    .select-input-error {
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-family: "gotham-book";
        padding: 10px 0px 0px 13px;
        border: 1px solid red;
    }

    .cards {
        margin: 0 auto;
        display: grid;
        grid-gap: 40px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .card {
        background-color: #F6F6F6;
        color: black;
        height: 4rem;
        font-size: 25px;
        font-family: "gotham-bold";
        height: 100%;
        border: none;
        .card-red
        {
            color: red;
        }
        .grid {
            display: grid;
            grid-template-columns: 230px auto;
        }
        .grid-full {
            display: grid;
            grid-template-columns: auto;
            justify-content: center;
        }
    }

    .grid-container {
        display: grid;
        grid-template-columns: 230px auto;
        grid-gap: 1px;
        // background-color: #2196F3;
        padding: 0px;
    }
      
    .grid-container > div {
        // background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 0px;
        font-size: 25px;
    }

    .input-field {
        border: #E3E3E3 1px solid;
        border-radius: 10px;
        width: 150px;
        height: 37px;
        text-align: center;
    }

    .pagination-button {
        display: inline-block;
        margin-bottom: 10px;
        // float: right;
        span {
            font-family: "gotham-bold";
        }
        button{
            text-decoration: none;
            transition: background-color .3;
            border: none;
            background-color: transparent;
        }
        button:hover{
            background-color: #ddd;
        }
    }

    .pop-up {
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pop-up-data {
        position: center;
        width: auto !important;
        background-color: white;
        border-radius: 30px;
        text-align: center;
        font-family: "gotham-bold";
        font-size: 25px;

        padding: 30px;
    }

    .pop-up-message {
        font-family: "gotham-bold";
        font-size: 30px;
        color: $c-beige;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .pop-up-message-content
    {
        font-family: "gotham-bold";
        font-size: 30px;
        color: $c-beige;
        display: flex;
        justify-content: center;
    }

    .pop-up-button {
        border: none;
        border-radius: 5px;
        background-color: $c-orange;
        color: $c-white;
        padding: 5px;
        width: 250px;
    }

    .date-input {
        width: calc(100% - 40px);
    }

    @media screen and (max-width: 910px) {
        .pop-up-data {
            position: center;
            width: 90% !important;
            background-color: white;
            border-radius: 30px;
            text-align: center;
            font-family: "gotham-bold";
            font-size: 25px;
            padding: 30px;
        }
        .pop-up-message-content
        {
        font-family: "gotham-bold";
        font-size: 30px;
        color: $c-beige;
        display: block;
        justify-content: center;
        }
    }

    .popup-container {
        width: 700px;
        padding: 20px 0px;
        margin: 0 auto;
      
        .popup-header {
          font-size: 30px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 20px;
          color: $c-beige;
          padding-bottom: 12px;
        }
      
        .popup-form {
          display: flex;
          flex-direction: column;
          gap: 20px;
      
          .form-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
      
            label {
              width: 150px;
              text-align: left;
              font-size: 20px;
              color: $c-beige;
      
              span {
                white-space: nowrap;
              }
            }
      
            input,
            textarea, select {
              flex: 1;
              height: 48px;
              padding: 10px 12px;
              border: 1px solid $c-border-gray;
              border-radius: 6px;
              font-family: $avenir-next-weight-500;
              font-size: 18px;
              color: $c-dark-gray;
      
              &::placeholder {
                color: $c-weathered-stone;
              }
      
              &:focus {
                border: 2px solid $c-cyan;
                outline: none;
              }
            }
          }
        }
      }
      
      /* Responsive Breakpoints */
      @media (max-width: 1024px) { // Medium screens
        .popup-container {
          width: 90%;
          padding: 20px;
      
          .popup-header {
            font-size: 24px;
          }
      
          .popup-form {
            .form-row {
              label {
                font-size: 18px;
                text-align: left;
                width: 130px;
              }
      
              input,
              textarea {
                font-size: 14px;
              }
            }
          }
        }
      }
      
      @media (max-width: 768px) { // Tablets and small screens
        .popup-container {
          width: 95%;
          padding: 15px;
      
          .popup-header {
            font-size: 20px;
          }
      
          .popup-form {
            .form-row {
              flex-direction: column;
              align-items: flex-start;
              gap: 5px;
      
              label {
                width: 100%;
                text-align: left;
                font-size: 16px;
              }
      
              input,
              textarea {
                width: 100%;
                font-size: 14px;
              }
            }
          }
        }
      }
      
      @media (max-width: 480px) { // Mobile screens
        .popup-container {
          width: 100%;
          padding: 10px;
      
          .popup-header {
            font-size: 18px;
          }
      
          .popup-form {
            gap: 15px;
      
            .form-row {
              label {
                font-size: 14px;
              }
      
              input,
              textarea {
                height: 40px;
                font-size: 14px;
              }
            }
          }
        }
    }
}