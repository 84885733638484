@import "styles/font.scss";
@import "styles/variables.scss";
@import "styles/mixin.scss";
.page-forgot-password {
    height: 100%;
    header {
        background-color: #A28559;
        img {
            height: 100px;
            padding: 10px;
            color-interpolation-filters: white;
        }
    }
    .grid-container {
        display: grid;
        grid-template-columns: 50% 50%;
        height: 100%;
        .grid-img {
            position: relative;
            text-align: right;
            color: white;
            background: transparent linear-gradient(54deg, #FFFFFF 0%, #000000 100%) 0% 0% no-repeat padding-box;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                mix-blend-mode: multiply;
                opacity: 0.7;
            }
            .top-right-title {
                position: absolute;
                top: 12px;
                right: 16px;
                font-size: 90px;
                font-family: "gotham-bold";
                line-height: 1.1;
            }
        }
        .grid-context {
            display: grid;
            grid-template-columns: auto auto;
            padding: 15px;
            padding-bottom: 40px;
            background-color: #F6F6F6;
            border-radius: 8px;
            .grid-info {
                img {
                    width: 300px;
                }
                .img-forgotPwd {
                    width: 300px;
                }
                .img-changePwd {
                    width: 250px;
                }
                p {
                    color: #A28559;
                    font-size: 45px;
                    line-height: 1.1;
                    font-family: "gotham-bold";
                }
                label {
                    color: #1D1D1B;
                    font-size: 28px;
                    line-height: 1.1;
                    font-family: "gotham-light";
                }
                span {
                    color: #A28559;
                    font-size: 36px;
                    line-height: 1.1;
                    font-family: "gotham-bold";
                }
            }
            .grid-span {
                grid-column: 1 / span 2;
                background-color: white;
                padding-bottom: 25px;
                margin: 0px 20px 0px 20px;
                border-radius: 8px;
                .grid-button {
                    // display: flex;
                    // flex-direction: row;
                    // justify-content: center;
                    // align-items: center;
                    margin-top: 3px;
                    background-color: #A28559;
                    color: #FFFFFF;
                    border-radius: 8px;
                    border: none;
                    height: 45px;
                    width: 110px;
                    font-size: 18px;
                    font-family: "gotham-book";
                    float: right;
                    position: absolute;
                    right: 112px;
                    // position: relative;
                    // top: 48px;
                    // right: 60px;
                }
                .grid-button_grey {
                    margin-top: 3px;
                    background-color: gray;
                    color: #FFFFFF;
                    border-radius: 8px;
                    border: none;
                    height: 45px;
                    width: 120px;
                    font-size: 18px;
                    font-family: "gotham-book";
                    float: right;
                    position: absolute;
                    right: 112px;
                }
            }
        }
        .grid-button_next {
            margin: 30px 120px;
        }
    }
    @media(max-width:1236px) {
        .grid-container {
            display: inline;
            grid-template-columns: 100%;
            .grid-img {
                height: 350px;
            }
            .grid-context {
                .grid-span {
                    .grid-button {
                        right: 185px;
                    }
                }
            }
        }
    }
    @media(max-width:821px) {
        .grid-container {
            display: inline;
            grid-template-columns: 100%;
            .grid-img {
                .top-right-title {
                    font-size: 70px;
                }
            }
            .grid-context {
                .grid-span {
                    .grid-button {
                        right: 148px;
                    }
                }
            }
        }
    }
    @media(max-width:769px) {
        .grid-container {
            display: inline;
            grid-template-columns: 100%;
            .grid-img {
                height: 300px;
                .top-right-title {
                    font-size: 70px;
                }
            }
            .grid-context {
                .grid-span {
                    .grid-button {
                        right: 115px;
                    }
                }
            }
        }
    }
    &__password-requirement-wrapper {
        // font-family: $avenir-next-weight-400;
        font-size: 12px;
        color: #A28559;
        display: grid;
        grid-template-columns: auto auto;
        padding-left: 6%;
        @include max-width(1180px) {
            padding-left: 270px;
        }
        @include max-width(1024px) {
            padding-left: 190px;
        }
        @include max-width(821px) {
            padding-left: 13%;
        }
        @include max-width(769px) {
            padding-left: 10%;
        }
    }
    &__password-requirement {
        align-items: center;
        display: flex;
        margin-top: 4px;
    }
    &--error {
        color: red;
    }
    &--success {
        color: green;
    }
    .code-button {
        width: 100%;
        display: flex;
        justify-content: center;
        div {
            width: 550px;
            .grid-button1 {
                float: right;
                position: relative;
                top: 48px;
                margin-right: 3px;
                background-color: #A28559;
                color: #FFFFFF;
                border-radius: 8px;
                border: none;
                font-size: 18px;
                font-family: "gotham-book";
                height: 45px;
                width: 110px;
            }
            .grid-button1_grey {
                float: right;
                position: relative;
                top: 48px;
                margin-right: 3px;
                background-color: gray;
                color: #FFFFFF;
                border-radius: 8px;
                border: none;
                font-size: 18px;
                font-family: "gotham-book";
                height: 45px;
                width: 120px;
            }
        }
    }
}