.page-user-management {
  // zoom: 80%;
  height: 100%;
  padding: 5px 15px 0px 15px;
  background-color: #ffffff;
  width: 100%;
  // min-width: $min-width;

  .fixed-content {
    min-width: 1881.59px;
  }
  .top-context {
    height: 90px;
  }
  .error {
    display: inline-block;
    padding-top: 10px;
  }
  .title {
    float: left;
    font-size: 60px;
    color: $c-beige;
    font-family: "gotham-bold";
    letter-spacing: 0;
    text-align: left;
  }
  .search-bar {
    width: 400px;
    float: left;
    padding-top: 30px;
    margin-left: 20px;
  }
  .search-button {
    width: 180px;
    float: right;
    padding-right: 10px;
    height: 45px;
    margin-top: 20px;
  }
  .table-layout {
    overflow-y: auto;
    height: 500px;
    border-radius: 15px;
    width: 100%;
  }
  .table-layout::-webkit-scrollbar {
    display: none;
  }
  table {
    width: 100%;
    padding: 5px 15px 0px 15px;
    border-collapse: collapse;
  }
  thead th {
    background-color: #f6f6f6;
    position: sticky;
    top: 0;
  }
  table.center {
    margin-left: auto;
    margin-right: auto;
    th {
      text-decoration: underline;
      font-family: "gotham-bold";
    }
    td {
      font-family: "gotham-book";
    }
  }
  tbody tr:nth-child(odd) {
    background-color: #dddddd;
  }
  tr:nth-child(even) {
    background-color: #f6f6f6;
  }
  th,
  td {
    text-align: center;
    padding: 10px 0px 10px 0px;
    font-size: 18px;
  }
  .text-field {
    font-size: 25px;
    font-family: "gotham-bold";
    align-self: flex-end;
  }
  .top-padding {
    div {
      padding-top: 10px;
    }
  }
  .alignBottom {
    align-self: flex-end;
  }
  .input-field {
    padding: 40px;
    background-color: #f6f6f6;
    margin: 30px 50px 30px 50px;
  }
  .text-field {
    font-size: 25px;
    font-family: "gotham-bold";
  }

  .check-box {
    zoom: 1.5;
    transform: scale(1.5);
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    -moz-transform: scale(1.5);
    transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    margin-right: 8px;
  }

  .pagination-button {
    display: inline-block;
    margin-bottom: 10px;
    float: right;
    span {
      font-family: "gotham-bold";
    }
    button {
      text-decoration: none;
      transition: background-color 0.3;
      border: none;
      background-color: transparent;
    }
    button:hover {
      background-color: #ddd;
    }
  }
  .resetPswButton {
    background-color: #9d9d9c;
    color: white;
    border: none;
    border-radius: 8px;
    font-family: "gotham-bold";
    padding: 3px 5px 3px 5px;
    width: 165px;
    height: 30px;
    margin-top: 8px;
  }
  .update-page {
    justify-content: center;
  }
}
