.app-row-select {
    font-size: $text-size-m;
    &__button {
        border-top: none;
        border-left: none;
        border-bottom: none;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
        background-color: transparent;
        color: $c-weathered-stone;
        font-family: $avenir-next-weight-500;
        height: 20px;

        &:last-child {
            border-right: none;
        }
    }

    &__selecting {
        color: $c-dark-blue;
        font-family: $avenir-next-weight-600;
    }
}
