.page-merchant-list {
    zoom: 80%;
    height: 100%;
    padding: 5px 15px 0px 15px;
    background-color: #FFFFFF;
    width: 100%;

    .fixed-content{
        // width: 98%;
        // position: fixed;
        min-width: 1881.59px;
    }
    .top-context {
        height: 90px;
    }
    .title {
        float: left;
        font-size: 60px;
        color: $c-beige;
        font-family: "gotham-bold";
        letter-spacing: 0;
        text-align: left;
    }
    .search-bar {
        width:400px;
        float: left;
        padding-top: 30px;
        margin-left: 20px;
    }
    .search-button {
        width: 220px;
        float: right;
        padding-right: 10px;
        height: 50px;
        margin-top: 20px;
    }
    .input-field {
        border: #E3E3E3 1px solid;
        border-radius: 10px;
        width: 205px;
        height: 37px;
        text-align: center;
    }
    .pagination-button {
        display: inline-block;
        margin-bottom: 10px;
        float: right;
        span {
            font-family: "gotham-bold";
        }
        button{
            text-decoration: none;
            transition: background-color .3;
            border: none;
            background-color: transparent;
        }
        button:hover{
            background-color: #ddd;
        }
    }
    .pop-up {
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pop-up-data {
        position: center;
        width: auto !important;
        background-color: white;
        border-radius: 30px;
        text-align: center;
        font-family: "gotham-bold";
        font-size: 25px;

        padding: 30px;
    }

    .pop-up-message {
        font-family: "gotham-bold";
        font-size: 30px;
        color: $c-beige;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .pop-up-message-contents
    {
        font-family: "gotham-bold";
        font-size: 30px;
        color: $c-beige;
        display: flex;
        justify-content: center;
    }

    .pop-up-button {
        border: none;
        border-radius: 5px;
        background-color: $c-orange;
        color: $c-white;
        padding: 5px;
        width: 250px;
    }

    .date-input {
        width: calc(100% - 40px);
    }

    @media screen and (max-width: 910px) {
        .pop-up-data {
            position: center;
            width: 90% !important;
            background-color: white;
            border-radius: 30px;
            text-align: center;
            font-family: "gotham-bold";
            font-size: 25px;
            padding: 30px;
        }
        .pop-up-message-content
        {
        font-family: "gotham-bold";
        font-size: 30px;
        color: $c-beige;
        display: block;
        justify-content: center;
        }
    }
    &__react-table-wrapper {
        .react-table {
            &__icon {
                position: absolute;
                top: 9px;
                left: 5px;
                width: 17px;
                height: 17px;
                border: solid $c-table-icon;
                border-width: 5px 0px 0px 5px;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &__icon-wrapper {
                position: relative;
                border: 3.5px solid $c-table-icon;
                border-radius: 9px;
                margin-left: 8px;
                color: $c-table-icon;
                height: 35px;
                width: 35px;
                align-items: center;
                justify-content: center;
                display: none;

                &-down {
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }

                &--show {
                    display: inline-block;
                }
            }

            &__header {
                display: flex;
                align-items: center;

                &-title {
                    text-decoration: underline;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            &__search-button {
                border: none;
                color: $c-white;
                width: 60px;
                height: 30px;
                background-color: $c-orange;
                border-radius: 10px;
            }
        }
    }
    .popup-container {
        width: 700px;
        padding: 20px 0px;
        margin: 0 auto;
      
        .popup-header {
          font-size: 32px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 20px;
          color: $c-beige;
          padding-bottom: 12px;
        }
      
        .popup-form {
          display: flex;
          flex-direction: column;
          gap: 20px;
      
          .form-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 18px;
      
            label {
              width: 160px;
              text-align: left;
              font-size: 20px;
              color: $c-beige;
      
              span {
                white-space: nowrap;
              }
            }
      
            input,
            textarea, select {
              flex: 1;
              height: 48px;
              padding: 10px 12px;
              border: 1px solid $c-border-gray;
              border-radius: 6px;
              font-family: $avenir-next-weight-500;
              font-size: 18px;
              color: $c-dark-gray;
      
              &::placeholder {
                color: $c-weathered-stone;
              }
      
              &:focus {
                border: 2px solid $c-cyan;
                outline: none;
              }
            }
          }
        }
      }
      
      /* Responsive Breakpoints */
      @media (max-width: 1024px) { // Medium screens
        .popup-container {
          width: 90%;
          padding: 20px;
      
          .popup-header {
            font-size: 24px;
          }
      
          .popup-form {
            .form-row {
              label {
                text-align: left;
                font-size: 18px;
                width: 130px;
              }
      
              input,
              textarea {
                font-size: 14px;
              }
            }
          }
        }
      }
      
      @media (max-width: 768px) { // Tablets and small screens
        .popup-container {
          width: 95%;
          padding: 15px;
      
          .popup-header {
            font-size: 20px;
          }
      
          .popup-form {
            .form-row {
              flex-direction: column;
              align-items: flex-start;
              gap: 5px;
      
              label {
                text-align: left;
                width: 100%;
                font-size: 16px;
              }
      
              input,
              textarea {
                width: 100%;
                font-size: 14px;
              }
            }
          }
        }
      }
      
      @media (max-width: 480px) { // Mobile screens
        .popup-container {
          width: 100%;
          padding: 10px;
      
          .popup-header {
            font-size: 18px;
          }
      
          .popup-form {
            gap: 15px;
      
            .form-row {
              label {
                font-size: 14px;
              }
      
              input,
              textarea {
                height: 40px;
                font-size: 14px;
              }
            }
          }
      
          .form-buttons {
            button {
              font-size: 14px;
              height: 40px;
            }
          }
        }
    }
}