.Toastify {
    z-index: 99999 !important;
    &__toast {
        min-height: 48px;
        width: 550px;
        box-shadow: none;
        border-radius: 8px;
        @include max-width($scr-xs) {
            width: 400px;
        }
        color: $c-maire !important;
        font-family: $gotham-weight-200 !important;
    }

    &__toast-container {
        width: auto !important;
    }

    &__close-button {
        align-self: center;
        > svg {
            height: 18px;
            width: 18px;
        }
    }
}

.rc-scrollbars {
    &__with-navbar {
        height: calc(100% - 100px) !important;
    }
}
