/* -- Color -- */
$c-body: #f3f3f6;
$c-primary: rgba(28, 28, 28, 1);
$c-placeholder: #919191;
$c-tableHeader: #e5e5e5;
$c-scrollbar: #c2c2c5;
$c-logo: #133774;
$c-border-gray: #d9d9d9;
$c-white: #ffffff;
$c-cyan: #3e868e;
$c-light-green: #29cc97;
$c-green: #08d175;
$c-red: #ff0000;
$c-gray: #707070;
$c-light-gray: #F6F6F6;
$c-weathered-stone: #c4c4c4;
$c-dark-gray: #323232;
$c-lynx-white: #f7f7f7;
$c-grayish-blue: #a3a6ac;
$c-dark-blue: #232331;
$c-light-orange: #d1b896;
$c-blue: #36476f;
$c-black: #323232;
$c-beige: #a28559;
$c-maire:#1D1D1B;
$c-nenon-green:#39ED00;
$c-lg-gray:#878787; 
$c-silver: #C6C6C6;
$c-table-gray: #e3e3e3;
$c-table-icon: #9d9d9c;
$c-darker-gray: #1d1d1b;
$c-orange: #f5903d;
$c-star-dust:#9D9D9C;
$c-blg-gray:#D8D8D8;
$c-bclg-gray:#8c8b8b;
$c-box-black-shadow:#c1c1c1;

/* -- Font size -- */
$text-size-xxxxl: 60px;
$text-size-xxxl: 48px;
$text-size-xxl: 32px;
$text-size-xl: 28px;
$text-size-mxl: 24px;
$text-size-l: 22px;
$text-size-m: 18px;
$text-size-body: 16px;
$text-size-s: 14px;
$text-size-xs: 12px;
$text-size-xxs: 10px;

/* -- Font weight -- */
$text-weight-strong: 900;
$text-weight-extra-bold: 800;
$text-weight-bold: 700;
$text-weight-semi-bold: 600;
$text-weight-medium: 500;
$text-weight-normal: 400;
$text-weight-light: 300;
$text-weight-extra-light: 200;
$text-weight-thin: 100;

/* -- Font family -- */
$avenir-next-weight-900: avenirNext-black;
$avenir-next-weight-800: avenirNext-extrabold;
$avenir-next-weight-700: avenirNext-bold;
$avenir-next-weight-600: avenirNext-semiBold;
$avenir-next-weight-500: avenirNext-medium;
$avenir-next-weight-400: avenirNext-regular;
$avenir-next-weight-300: avenirNext-light;
$avenir-next-weight-200: avenirNext-extra-light;
$avenir-next-weight-100: avenirNext-thin;

$avenir-next-weight-900: avenirNext-black;
$avenir-next-weight-800: avenirNext-extrabold;
$avenir-next-weight-700: avenirNext-bold;
$avenir-next-weight-600: avenirNext-semiBold;
$avenir-next-weight-500: avenirNext-medium;
$avenir-next-weight-400: avenirNext-regular;
$avenir-next-weight-300: avenirNext-light;
$avenir-next-weight-200: avenirNext-extra-light;
$avenir-next-weight-100: avenirNext-thin;

$gotham-weight-400: gotham-bold;
$gotham-weight-300: gotham-medium;
$gotham-weight-200: gotham-book;
$gotham-weight-100: gotham-light;

$segoe-weight-100 : segoe-ui;

/* -- Media query -- */
$scr-lg: "1199px";
$scr-md: "991px";
$scr-sm: "768px";
$scr-xs: "679px";


$min-width:1920px;