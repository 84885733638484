.page-home-page {
  background-color: white;
  height: 100%;
  min-width: $min-width;
  // zoom: 81%;
  .home-page {
    zoom: 81%;
    &__top {
      border-bottom: 3px solid $c-beige;
      // display: grid;
      // grid-template-columns: 25% 75%;
    }
    &__title {
      font-size: 60px;
      font-family: "gotham-bold";
      color: $c-beige;
      // width: 450px;
      // text-align: center;
      padding: 0px 100px;
      justify-content: center;
      min-width: 319.98px;
      // margin-top:20px;
      // margin-bottom: 20px;
      // height:50px
    }
    &__vertical-line {
      height: 55px;
      width: 0.05px !important;
      min-width: 0.05px !important;
      // background-color:$c-beige ;
      margin: 25px 0px;
      border-left: 8px solid $c-beige;
    }
    &__subtitle {
      font-size: 26.24px;
      font-family: "gotham-bold";
      color: $c-beige;
      // width: 500px;
      // padding-left: 20px;
      margin: 18px 0px;
      padding: 0px;
      // align-self: flex-end;
      .username {
        font-size: 20px;
        font-family: "gotham-book";
        color: black;
      }
    }
    &__icon {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin: 20px 20px;
      color: $c-beige;
    }
  }

  .body-content {
    color: $c-body;
    margin: 25px 0px;
    width: 100% !important;
    font-size: 35px;
    font-family: "gotham-bold";
    text-align: center;
    padding-bottom: 50px;
    .justify-content {
      justify-content: space-evenly!important;
    }

    &__body-content-left {
      min-width: 797px;
      .title {
        background-color: $c-orange;
        border-radius: 10px 10px 0px 0px;
        color:$c-white;
      }

      .multiple-bar {
       
        background-color: $c-table-gray;
        height: 500px;
        border-radius: 0px 0px 10px 10px;
      }

      .icons-box {
        font-size: 1rem;
        background-color: white;
        color: black;
        text-align: center;
        
        max-width: 16.305%;
        margin-right: 4.2%;
        border-radius: 0px 0px 10px 10px;
        box-shadow: -3px 2px 1px $c-box-black-shadow,
      }

      .icons-last-box {
        font-size:1rem;
        background-color: white;
        color: black;
        text-align: center;
       max-width: 16.34%;
    
        border-radius: 0px 0px 10px 10px;
        box-shadow: -3px 2px 1px $c-box-black-shadow,
      }

      .img{
        width:auto;
        height:100%;
        align-self: center;
      }

      .bar-xaxis {
        margin-left: 1.99%;
        margin-right: 0.4%;
      }

      .bottom-left-title {
        color: $c-beige;
        font-size: 14px;
        font-family: "gotham-bold";
        text-align: left;
        display: flex;
        align-items: center;
      }

      .bottom-left-value {
        color: $c-orange;
        font-size: 47px;
        height: 55px;
        border-left: 5px solid $c-beige;
        display: flex;
        align-items: center;
      }

      .bottom-left {
        max-height: 65px;
        display: flex;
        align-items: center;
      }

 
    }

    &__body-content-right {
      min-width: 797px;
      .multiple-schedule {
        background-color: $c-table-gray;
        height: 552.5px;
       
        border-radius: 10px 10px 10px 10px;
      }
      .table-title {
        color: $c-beige;
        font-size: 25px;
        text-align: left;
      }

      .table-content {
        margin: 30px;
      }

      .bottom-right-title {
        color: $c-beige;
        font-size: 18px;
        font-family: "gotham-bold";
        text-align: left;
        display: flex;
        align-items: center;
        margin-left: 5px;
        // margin:10px
      }

      .bottom-right-value {
        color: $c-orange;
        font-size: 47px;
        border-left: 5px solid $c-beige;
        height: 40px;
        display: flex;
        align-items: center;
      }

      .bottom-right {
        height: 65px;
        background-color: $c-table-gray;
        border-radius:10px;
        display: flex;
        align-items: center;
      }

      .bottom-right-button {
        height: 40px;
      }
    }
  }

  .pop-up {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pop-up-content {
    position: center;
    width: 80% !important;
    max-height: 80%;
    overflow-y: auto;
    background-color: white;
    border-radius: 30px;
    // text-align: center;
    font-family: "gotham-bold";
    font-size: 25px;

    padding: 30px 30px 10px 30px;
  }
  
  .pop-up-button {
    border: none;
    border-radius: 5px;
    background-color: $c-orange;
    color: $c-white;
    padding: 5px;
    // width: 250px;
  }

  .pop-up-close-button {
    border: none;
    border-radius: 5px;
    background-color: $c-red;
    color: $c-white;
    padding: 5px;
    width: 250px;
  }

  .table-invoice {
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 20px;
    th {
      word-break: break-word;
      padding: 10px 0px 10px 20px;
      text-align: center;
    }
    tbody {
      overflow-y: scroll;
      td {
          // padding: 0px 0px 0px 20px;
          text-align: center;
      }
    }
  }

  .tr-head {
    tr:nth-child(odd) {
      background-color: #a28559;
    }
  }

  .tr-body {
    color: #1d1d1d;
    font-size: medium;
    font-family: "gotham-book";
    tr:nth-child(even) {
      background-color: $c-light-gray;
      height: 40px;
    }
    tr:nth-child(odd) {
      background-color: $c-white;
      height: 40px;
    }
  }

  .search-button {
    border: none;
    color: $c-white;
    width: 60px;
    height: 30px;
    background-color: $c-orange;
    border-radius: 10px;
  }
}

@media screen and (max-width: 910px) {
  
  .page-home-page .home-page .home-page__icon {
    
    margin-top: 1px;
    float: left;
    margin-right: 85%;
    
  }
  .table-invoice {
    font-size: 20px;
    border-collapse: collapse;
    table-layout: fixed;
    th {
      word-break: initial !important; 
      padding: 10px 0px 10px 20px;
      text-align: center;
    }
    tbody {
      overflow-y: scroll;
      td {
          // padding: 0px 0px 0px 20px;
          text-align: center;
      }
    }
    .th{
      display:none;
    }
  }

  .page-home-page .pop-up-content {
    width: 100% !important;
}
  

  .page-home-page .table-invoice th {
    padding: 10px 0px 10px 20px;
    text-align: center;
  }   

  .page-home-page .body-content__body-content-right {
    min-width: 797px;
    flex: 0 0 auto;
    width: 41.66666667%;
    margin: 0 50%;
  }

  .page-home-page .body-content__body-content-left {
    min-width: 797px;
    margin-top: 50px;
    width: 41.66666667%;
  }

  .page-home-page .body-content .justify-content {
    justify-content: space-evenly !important;
    margin: 0 -28%;
  }
}
