.open-vidu {
    &__wrapper {
        display: flex;
        position: relative;
        flex-direction: column;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }

    &__video-content {
        position: relative;
        background-color: black;
        margin: 0px 0px -7px 0px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
        height: 100%;
        width: 100%;
    }

    &__video-title {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $c-white;
        opacity: 0.8;
        padding: 0px 5px;
    }

    &__video-row {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        bottom: 0px;
        // margin-bottom: -6px;
        width: 100%;
    }

    &__video-mini {
        position: relative;
        background-color: black;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__buttons-wrapper {
        position: absolute;
        left: 0;
        margin-left: 50px;
        height: 55%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__button {
        border-radius: 999px;
        border-width: 0px;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        &--transparent {
            background-color: inherit;
            border: 2px solid $c-white;
        }
        &--red {
            background-color: #ff3f0d;
        }
        &--green
        {
            background-color: #00FF00;
        }
    }

    &__button-join {
        color: $c-white;
        background-color: #f5903d;
        border-radius: 6px;
        padding: 0px 15px;
        border-width: 0px;
        font-family: $gotham-weight-400;
    }

    &__button-join-disable {
        color: $c-white;
        background-color: #a3a6ac;
        border-radius: 6px;
        padding: 0px 15px;
        border-width: 0px;
        font-family: $gotham-weight-400;
        cursor: not-allowed;
    }

    &__end {
        font-family: $gotham-weight-300;
    }
}
