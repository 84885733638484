.app-table-column-header {
    height: 100%;
    align-items: center;
    display: flex;

    &__sort-button {
        border: none;
        padding: 0;
        margin-left: 5px;
        background-color: transparent;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 0.6;
        }
    }

    &__selecting {
        color: $c-cyan;
    }
}
