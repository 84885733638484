.transaction-page {
  zoom: 80%;
  height: 100%;
  background-color: #ffffff;
  margin-bottom: 50px;
  min-width: $min-width;
  .top-content {
    width: 100%;
    padding: 0px 0px 0px 30px;
    height: 140px;
    position: relative;
    .tabs {
      height: 140px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-end;
      cursor: pointer;
      float: left;
      width: 54%;
      .tablinks {
        min-width: 230px;
        height: 80px;
        float: left;
        font-family: "gotham-bold";
        font-size: x-large;
        text-align: center;
        color: white;
        border-radius: 10px 10px 0px 0px;
        padding: 22px 0px;
      }
      .tab-selected {
        background-color: #f6f6f6;
        color: #a28559;
      }
      .tab1 {
        background-color: #856e49;
      }
      .tab2 {
        background-color: #a28559;
      }
      .tab3 {
        background-color: #dcc7aa;
      }
    }
    .side-tabs {
      height: 140px;
      float: left;
      width: 45%;
      padding-right: 50px;
      .search-bar {
        width: 400px;
        float: left;
        padding-top: 30px;
      }
      .search-button {
        width: 150px;
        float: right;
        margin-top: 30px;
        height: 40px;
      }
      .input-field {
        border: #e3e3e3 1px solid;
        border-radius: 10px;
        width: 150px;
        height: 37px;
        text-align: center;
      }
    }
  }

  .content-color {
    background-color: #f6f6f6;
  }

  .table-content {
    background-color: #f6f6f6;
    margin: 0px 50px 10px 30px;
  }

  .pop-up {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pop-up-data {
    position: center;
    width: 52% !important;
    background-color: white;
    border-radius: 30px;
    text-align: center;
    font-family: "gotham-bold";
    font-size: 25px;

    padding: 30px 30px 10px 30px;
  }

  .pop-up-message {
    font-family: "gotham-bold";
    font-size: 30px;
    color: $c-beige;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pop-up-button {
    border: none;
    border-radius: 5px;
    background-color: $c-orange;
    color: $c-white;
    padding: 5px;
    width: 250px;
  }
  .pop-up-close-button {
    border: none;
    border-radius: 5px;
    background-color: $c-red;
    color: $c-white;
    padding: 5px;
    width: 250px;
  }

  .pagination-button {
    display: inline-block;
    margin-bottom: 10px;
    float: right;
    span {
      font-family: "gotham-bold";
    }
    button {
      text-decoration: none;
      transition: background-color 0.3;
      border: none;
      background-color: transparent;
    }
    button:hover {
      background-color: #ddd;
    }
  }

  .column {
    float: left;
    width: 50%;
    padding: 10px;
    color: black;
  }

  /* Clear floats after the columns */
  .row:after {
    display: table;
    clear: both;
  }

  .span-title {
    font-family: "gotham-bold";
  }
  .span-detail {
    font-family: "gotham-book";
  }

  .table-invoice {
    display: flex;
    justify-content: center;
    font-size: 22px;
    margin-bottom: 15px;
    th {
      padding: 10px 0px 10px 20px;
    }
    tbody {
      overflow-y: scroll;
      // td {
      //     padding: 0px 0px 0px 20px;
      // }
    }
  }

  .tr-head {
    tr:nth-child(odd) {
      background-color: #a28559;
    }
  }

  .tr-body {
    color: #1d1d1d;
    font-size: large;
    font-family: "gotham-book";
    tr:nth-child(even) {
      background-color: $c-light-gray;
      height: 40px;
    }
    tr:nth-child(odd) {
      background-color: $c-white;
      height: 40px;
    }
  }
}
