.page-loan-settings {
    zoom: 80%;
    background-color: white;
    min-width: $min-width;
    .loan-settings {
        // &__left-right{
        //     display: grid;
        //     grid-template-columns: 50% 50%;
        // }
        // .green {
        //     background-color: green;
        // }

        &__left-photo {
            height: 980px;
            background-image: url("../../assets/images/pages/loan-settings.jpg");
            background-position: -580px;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            min-width: 978px;
            .left-content {
                font-family: "gotham-bold";
                font-size: 100px;
                width: 100%;
                text-align: right;
                margin-right: 40px;
                color: white;
            }
        }
      
        &__right-cal {
            margin: auto;
            min-width: 972px;
            // background-color: aqua;

            .c-orange {
                background-color: $c-orange;
                color: $c-white;
            }

            .c-beige {
                background-color: $c-beige;
                color: $c-white;
            }

            .w-light {
                font-family: "gotham-light";
                font-size: 30px;
            }

            .w-medium {
                font-family: "gotham-medium";
                font-size: 30px;
            }

            .w-bold {
                font-family: "gotham-bold";
                font-size: 25px;
            }

            .right-box {
                background-color: $c-body;
                width: 820.3px;
                // height: 680.76px;
                height: fit-content;
                border-radius: 15px;
                margin: auto;
                padding: 30px 40px 15px 40px;
                margin-bottom: 30px;
            }

            .rm-input-box{
                min-width: 196.31px;
            }

            .years-input-box{
                min-width:147.23px;
            }

            .percentage-input-box{
                max-width:196.23px;
            }

            .days-input-box{
                max-width: 147.22px;
            }

            .minimum-rm-input-box{
                min-width:257.23px;
            }
            
            .right-content {
                font-family: "gotham-light";
                font-size: 25px;
                color: $c-maire;
            }
            .cal-demo {
                text-align: center;
                border-radius: 10px;
                background-color: white;
            }

            .line {
                border-bottom: 2px solid $c-silver;
                padding-bottom: 10px;
                padding-top: 10px;
                margin-bottom: 10px;
            }

            .cal-demo-down-arrow {
                padding: 10px 0;
                // background-image: url("../../assets/images/pages/down-arrow.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                display: flex;
            }

            .min-max {
                margin: 5px 0px;
                border-radius: 10px;
                width: 100%;
            }

            .min-max > div {
                padding: 5px 0;
                text-align: center;
            }

            .min,
            .min-loan-amount-content,
            .max-loan-amount-content,
            .cal-demo-content {
                border-radius: 10px 0px 0px 10px;
            }

            .percentage,
            .min-year-content,
            .max-year-content {
                border-radius: 0px 10px 10px 0px;
            }

            .min-max-loan-amount {
                background-color: $c-body;
                text-align: center;
                border-radius: 10px;
                width: 100%;
                margin: auto;
            }

            .to {
                margin: auto;
                text-align: center;
            }

            .interest-rate-content {
                // display:grid;
                // grid-template-columns: 70% 30%;
                // width: 30%;
                //  background-color: white;
                border-radius: 10px;
                text-align: center;
                margin: auto;
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                // margin-bottom: 15px;
            }

            .tenure-year {
                // display:grid;
                // grid-template-columns: 50% 50%;
                // background-color: white;
                // border-radius: 10px;
                text-align: center;
                width: 100%;
                margin: auto;
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                // margin-bottom: 15px;
            }

            .cal-demo > div,
            .min-max-loan-amount > div,
            .interest-rate-content > div,
            .tenure-year > div {
                padding: 10px 0;
            }

            .update-button {
                text-align: center;
                width: 50%;
                margin: auto;
                border: none;
                font-size: 35px;
                border-radius: 41.5px;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            .button {
                text-align: center;

            }
        }
    }
}
