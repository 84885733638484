.modal {
    .page-logout {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        &__content {
            padding: 10px;
            width: 90% !important;
            min-width: 290px;
            max-width: 500px;
            height: 242px;
            max-height: 100%;
            border-radius: 10px;
            left: auto;
            display: flex;
            flex-direction: column !important;
            background-color: $c-white !important;
        }

        .header {
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: flex-end;
            border-bottom: 0;

            .modal-title {
                line-height: 0;
                margin: 0px;
            }

            &__close-button {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }

        .body {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__title {
                font-family: $avenir-next-weight-600;
                font-size: 26px;
                color: $c-dark-gray;
                margin-bottom: 20px;
            }

            &__description {
                font-family: $avenir-next-weight-500;
                font-size: 16px;
                color: $c-dark-gray;
                margin-bottom: 34px;
            }
        }

        .footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-top: 29px;

            > * {
                margin: 0px 15px 0px 15px;
            }

            &__left {
                font-family: $avenir-next-weight-500;
                font-size: 16px;
                color: $c-gray;
                cursor: pointer;
            }

            &__right {
                font-family: $avenir-next-weight-500;
                font-size: 16px;
                color: $c-cyan;
                cursor: pointer;
            }
        }
    }
}
