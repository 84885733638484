.app-input-time {
    .fields {
        width: 100%;
        overflow: hidden;
        padding: 10px 10px 10px 50px;
        .text-field {
            width: 20%;
            float: left;
            font-size: 25px;
            font-family: "gotham-bold";
            height: 46px;
            display: flex;
            align-items: center;
        }
        .input-field {
            input{
                width: 55%;
                border: none;
                border-radius: 10px;
                height: 46px;
                background: $c-white 0% 0% no-repeat padding-box;
            }
            button{
                background: $c-nenon-green 0% 0% no-repeat padding-box;
                color: $c-white;
                border: none;
                border-radius: 10px;
                height: 46px;
                width: 250px;
                font-size: 22px;
                font-family: "gotham-bold";
            }
        }
        .row-field {
            // padding-top: 20px;
            span {
                width: 100px;
                font-size: 25px;
                font-family: "gotham-bold";
                height: 46px;
                align-items: center;
                margin-right: 100px;
                float: left;
            }
            input {
                width: 60%;
                border: none;
                border-radius: 10px;
                height: 46px;
                background: $c-white 0% 0% no-repeat padding-box;
            }
            button {
                background: $c-nenon-green 0% 0% no-repeat padding-box;
                color: $c-white;
                border: none;
                border-radius: 10px;
                height: 46px;
                width: 250px;
                font-size: 22px;
                font-family: "gotham-bold";
            }
            div {
                width: 380px;
                font-size: 25px;
                font-family: "gotham-bold";
                height: 46px;
                align-items: center;
                float: left;
            }
            .input-number {
                width: 140px;
                border: none;
                border-radius: 10px;
                height: 46px;
                background: $c-white 0% 0% no-repeat padding-box;
            }
            .label-right {
                color: $c-orange;
                float: right;
            }
        }
    }
}