.app-confirmation-modal {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    width: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 65px 100px;
  }

  &__label {
    color: $c-beige;
    font-size: $text-size-xxl;
    text-align: center;
  }

  &__buttons-row {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__button-gap {
    width: 60px;
  }

  &__button-wrapper {
    height: 50px;
    width: 280px;
  }
}
