.app-success-message {
    z-index: 99;
    padding-top: 7px;
    padding-bottom: 8px;
    position: fixed;
    background-color: $c-light-green;
    color: $c-white;
    width: 100%;
    text-align: center;
    font-family: $avenir-next-weight-500;
    font-size: 16px;
}
