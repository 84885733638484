.dropdown-item {
    padding: 0 !important;
    color: $c-black;
}

.item-dropdown {
    height: 100%;
    max-width: 240px;
    @include max-width($scr-xs) {
        width: 100%;
    }

    &__caret {
        width: 16px;
    }

    &__toggle {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: $avenir-next-weight-600;
        font-size: 16px;
        border: none;
        width: 100%;
        height: 100%;
        cursor: pointer;
        justify-content: space-between;
        color: $c-cyan;

        &-button {
            width: 100%;
            border: none;
            display: flex;
            justify-content: space-between;
            background-color: transparent;
            color: $c-cyan;
            padding: 0px;
        }

        &-button-solid {
            background-color: $c-white !important;
            color: $c-black;
            border: 1px solid $c-border-gray;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            height: 40px;
            align-items: center;
            padding: 0 15px 0 15px;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    &__text--black {
        color: $c-black;
    }

    &--rotate {
        transform: rotateZ(180deg);
    }

    &__menu {
        padding: 0px !important;
        border: none;
        background-color: $c-lynx-white !important;
        box-shadow: 0 2px 2px 0 $c-border-gray;
        min-width: 240px !important;
        color: $c-red;

        &-item {
            height: 40px !important;
            font-family: $avenir-next-weight-500;
            font-size: 16px;
            cursor: pointer;
            color: $c-red;

            &-label {
                padding-left: 30px;
            }

            &:hover,
            &:active {
                background: $c-cyan !important;
                color: $c-white !important;
                stroke: $c-white !important;
            }

            &--selected {
                background-color: $c-cyan !important;
                color: $c-white !important;
            }
        }

        &-icon {
            margin-left: 20px;
            margin-right: -10px;
            width: 500px;
        }
    }
}
