.app-breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: $avenir-next-weight-600;
    font-size: $text-size-body;

    &__title {
        &--clicked {
            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }

            &:active {
                opacity: 0.6;
            }
        }

        &:last-child {
            color: $c-cyan;
        }
    }

    &__icon-right {
        margin-left: 10px;
        margin-right: 10px;
        height: 10px;
    }
}
