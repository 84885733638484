.pop-up-alert {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  &__pop-up-alert-content {
    position: center;
    // padding: 10px 63px 10px 63px;
    width: fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 10px !important;
    text-align: center;
    font-family: "gotham-bold";
    font-size: 25px;
    .msg-box {
      width: 400px;
      height: fit-content;
      // height: 25px;
    }

    .c-beige {
      background-color: $c-beige;
      color: $c-white;
    }

    .border-success {
      border: none;
    }

    .card {
      border-radius: 10px !important;
      border: none !important;
    }

    .card-header {
      border-radius: 10px 10px 0px 0px !important;
    }

    .card-footer {
      border-radius: 0px 0px 10px 10px !important;
    }

    .img {
      background-color: white;
    }

    .card-body-font {
      color: $c-beige;
    }

    .card-body {
      height: fit-content;
      font-size: 20px;
    }

    .card-title {
      height: 100px;
      width: 100px;
    }

    .img[alt]:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      margin: auto;
      text-align: center;
      content: attr(alt);
    }
    .rotate {
      animation: rotation 2s infinite linear;
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
}

// .pop-up-alert-content {
//     position: center;
//     // padding: 10px 63px 10px 63px;
//     width: fit-content;
//     height: fit-content;
//     background-color: white;
//     border-radius: 10px;
//     text-align: center;
//     font-family: "gotham-bold";
//     font-size: 25px;
// }
