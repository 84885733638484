.app-forgot-password-layout {
    height: 100%;
    
    &__header {
        min-height: 131px;
        background-image: url("../../../assets/images/pages/logo.png");
        background-color: $c-beige;
    }
    
}
