.page-report-payment {
    zoom: 80%;
    height: 100%;
    padding: 40px 50px 20px 50px;
    background-color: #FFFFFF;
    min-width: $min-width;
    /* Float four columns side by side */
    .column {
        float: left;
        // width: 25%;
        padding: 0 10px;
    }

    .left-side {
        width: 30%;
        .side-menu {
            overflow-y: auto;
            height: 700px;
            a {
                color: #C6C6C6;
                display: block;
                padding: 12px;
                text-decoration: none;
                border-top: 1px solid #C6C6C6;
                border-bottom: 1px solid #C6C6C6;
                cursor: pointer;
                font-size: 25px;
                font-family: "gotham-bold";
            }
            .clicked{
                background-color: #FFFFFF;
                color: #A28559;
            }
        }
    }

    .right-side {
        width: 70%;
        // background-color: $c-light-gray;
        .title-button {
            border: none;
            border-radius: 8px;
            background-color: #f5903d;
            color: #FFFFFF;
            font-family: "gotham-bold";
            font-size: 23px;
            height: 50px;
            // width: 25%;
        }
        .title-text {
            font-size: 50px;
            font-family: "gotham-bold";
            color: #A28559;
            // width: 65%;
        }
        .subtitle-text {
            font-size: 25px;
            font-family: "gotham-bold";
            color: #1D1D1B;
        }
        .menu1 {
            width: 65%;
            height: 120px;
            float: left;
            font-size: 22px;
            .date-select {
                width: 100%;
                margin-bottom: 20px;
                span {
                    font-family: "gotham-bold";
                    margin-right: 5px;
                }
                input{
                    width: 300px;
                    height: 36px;
                    border: 1px solid #D9D9D9;
                    border-radius: 4px;
                    padding-left: 10px;
                }
            }
        }
        .menu2 {
            width: 35%;
            height: 120px;
            float: left;
            font-size: 22px;
            .generate-btn {
                position: absolute;
                top: 140px;
                right: 100px;
                border: none;
                border-radius: 8px;
                background-color: $c-beige;
                color: $c-white;
            }
        }
        .half-menu{
            width: 48%;
            // height: 100px;
            float: left;
            font-size: 22px;
            .date-select {
                width: 100%;
                margin-bottom: 20px;
                span {
                    font-family: "gotham-bold";
                    margin-right: 5px;
                }
                // input{
                //     width: 300px;
                //     height: 36px;
                //     border: 1px solid #D9D9D9;
                //     border-radius: 4px;
                //     padding-left: 10px;
                // }
                .merchant-input-text {
                    height: 39px;
                    border-radius: 4px;
                    border: 1px solid #D9D9D9;
                    outline: none;
                    padding-left: 10px;
                    width: 285px;
            
                    &::placeholder {
                        color: $c-weathered-stone;
                    }
            
                    &:focus {
                        border: 2px solid $c-cyan;
                    }
        
                }
        
                .merchant-input-text-error {
                    height: 36px;
                    border-radius: 4px;
                    border: 2px solid $c-red !important;
                    outline: none;
                    padding-left: 10px;
                    width: 285px;
            
                    &::placeholder {
                        color: $c-weathered-stone;
                    }
            
                    &:focus {
                        border: 2px solid $c-cyan;
                    }
        
                }
            }
            .check-void {
                width: 100%;
                margin-bottom: 20px;
                display: flex;
                input{
                    width: 100px;
                    height: 36px;
                    border: 1px solid #D9D9D9;
                    border-radius: 4px;
                    padding-left: 10px;
                }
                span {
                    font-family: "gotham-bold";
                    margin-right: 5px;
                }
            }
        }
        .third-tab {
            font-size: 22px;
            font-family: "gotham-bold";
            height: 630px;
            min-width: 821px;
            padding-left: 25px;
            padding-right: 25px;
            .top-date{
                display: block;
                .split-block {
                    width: 49%;
                    float: left;
                    .span-input {
                        
                        width: 120px;
                        float: left;
                    }
                    input {
                        font-family: "gotham-book";
                        padding-left: 10px;
                    }
                }
            }
            .search-button {
                margin-top: 20px;
                padding-right: 20px;
            }
            .filename{
                width: 800px;
                height: 50px;
                color:black;
                background-color: white;
                display:flex;
                justify-content: space-between;
                align-items: center;
                
            }

           .selected-filename{
                padding-left:10px ;
                padding-right:10px ;
                font-family: "gotham-book";
            }
        }
    }

    /* Remove extra left and right margins, due to padding */
    .row {margin: 0 -5px;}

    /* Clear floats after the columns */
    .row:after {
      content: "";
      display: table;
      clear: both;
    }

    /* Responsive columns */
    @media screen and (max-width: 1000px) {
        .column {
            // width: 100%;
            display: block;
            margin-bottom: 20px;
        }
        .left-side {
            width: 100%;
            .side-menu {
                overflow-y: auto;
                height: 200px;
            }
        }

        .right-side {
            width: 100%;
        }
    }

    /* Style the counter cards */
    .card {
    //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      padding: 16px 8px 16px 16px;
      text-align: left;
      background-color: #eee;
    }

    .side-menu::-webkit-scrollbar,.dashBoardBody::-webkit-scrollbar,.customerDetailsBody::-webkit-scrollbar {
        // display: none;
        width: 10px;
    }
    .side-menu::-webkit-scrollbar-track,.dashBoardBody::-webkit-scrollbar-track,.customerDetailsBody::-webkit-scrollbar-track   {
        background: #f1f1f1;
        border-radius: 8px;
    }
    .side-menu::-webkit-scrollbar-thumb,.dashBoardBody::-webkit-scrollbar-thumb,.customerDetailsBody::-webkit-scrollbar-thumb   {
        background: #888;
        border-radius: 8px;
    }

    .display {
        //display: contents;
        position: relative;
    }

    .hide {
        display: none;
    }

    .pop-up {
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.05);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
    .pop-up-data-pay {
      position: center;
      width: 52% !important;
      background-color: white;
      border-radius: 30px;
      // text-align: center;
      font-family: "gotham-bold";
      font-size: 25px;

      padding: 30px 30px 10px 30px;
    }

    .pop-up-data-report {
      position: center;
      width: 80% !important;
      background-color: white;
      border-radius: 30px;
      text-align: center;
      font-family: "gotham-bold";
      font-size: 25px;

      padding: 30px 30px 10px 30px;
    }
    
    .pop-up-message {
      font-family: "gotham-bold";
      font-size: 30px;
      color: $c-beige;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    
    .pop-up-button {
      border: none;
      border-radius: 5px;
      background-color: $c-orange;
      color: $c-white;
      padding: 5px;
      width: 250px;
    }

    .pop-up-close-button {
      border: none;
      border-radius: 5px;
      background-color: $c-red;
      color: $c-white;
      padding: 5px;
      width: 250px;
    }

    .dateInput-style {
        border-radius: 5px;
        border: 1px solid #D9D9D9;
        padding: 5px;
        font-size: 20px;
    }

    .column-popup {
        // float: left;
        width: 50%;
        // padding: 10px;
        color: black;
    }

    // /* Clear floats after the columns */
    // .row:after {
    //   display: table;
    //   clear: both;
    // }

    .span-title {
      font-family: "gotham-bold";
    }
    .span-detail {
      font-family: "gotham-book";
    }

    .table-invoice {
      display: flex;
      justify-content: center;
      font-size: 20px;
      margin-bottom: 15px;
      margin-top: 20px;
      th {
        word-break: break-word;
        padding: 10px 0px 10px 20px;
      }
      tbody {
        overflow-y: scroll;
        td {
            // padding: 0px 0px 0px 20px;
            text-align: center;
        }
      }
    }

    .tr-head {
      tr:nth-child(odd) {
        background-color: #a28559;
      }
    }

    .tr-body {
      color: #1d1d1d;
      font-size: medium;
      font-family: "gotham-book";
      tr:nth-child(even) {
        background-color: $c-light-gray;
        height: 40px;
      }
      tr:nth-child(odd) {
        background-color: $c-white;
        height: 40px;
      }
    }

    .check-box {
        zoom: 1.5;
        transform: scale(1.5);
        -ms-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        -moz-transform: scale(1.5);
        transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        margin-right: 8px;
      }

      .upload-file-input {
        height: 60px;
        border-radius: 4px;
        border: 1px solid $c-border-gray;
        outline: none;
        font-family: $avenir-next-weight-500;
        font-size: 18px;
        color: $c-dark-gray;
        padding: 0px 0px 0px 15px;
        width: 600px;

        &::placeholder {
            color: $c-weathered-stone;
        }

        &:focus {
            border: 2px solid $c-cyan;
        }
    }

    .upload-file-input-error {
        height: 60px;
        border-radius: 4px;
        border: 2px solid $c-red !important;
        outline: none;
        font-family: $avenir-next-weight-500;
        font-size: 20px;
        color: $c-dark-gray;
        padding: 0px 0px 0px 15px;
        width: 500px;

        &::placeholder {
            color: $c-weathered-stone;
        }

        &:focus {
            border: 2px solid $c-cyan;
        }
    }

}