.app-auth-input {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__icon {
        height: 22px;
        width: 22px;
        object-fit: contain;
        position: absolute;
        margin-left: -40px;
    }

    &__input-wrapper {
        display: flex;
        flex-direction: row;
        flex: 1;
        height: 100%;
        position: relative;
    }

    &__input {
        width: 100%;
        height: 56px;
        font-size: $text-size-body;
        align-items: center;
        border-radius: 4px;
        padding-left: 20px;
        font-family: $avenir-next-weight-400;
        border: 0;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $c-placeholder;
        }

        &::-ms-reveal {
            display: none;
        }
    }

    &--error {
        border: 3px solid $c-red !important;

        &::placeholder {
            color: $c-red !important;
        }
    }

    &__password-eye {
        object-fit: contain;
        display: flex;
        position: absolute;
        right: 10px;
        height: 100%;
        align-items: center;
    }

    &__error {
        color: $c-red;
        font-size: $text-size-xs;
        min-height: 20px;
        padding: 0px !important;
        margin: 0px !important;
    }

    &__icon-input {
        background-color: #fff;
        border: 1px solid grey;
        border-radius: 10px;
        display: inline-flex;
        overflow: hidden;
        .icon {
            font-size: inherit;
            flex: 0 1 auto;
            padding: .7em;
        }
        .icon-action {
            cursor: pointer;
        }
        input {
            background-color: transparent;
            border: 0;
            font-size: inherit;
            flex: 1 0 auto;
            min-width: 100px;
            padding: .5em;
            &:focus {
                outline: none;
            }
    
            &::placeholder {
                color: $c-placeholder;
            }
        }
    }

    .input-70 {
        width: 70%;
    }
}
