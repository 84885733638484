.bg-color {
    --bs-bg-opacity: 1;
    background-color: #a28559;
    width: 100%;
    margin: -13px 0;
    //padding: 0 40px !important;
}
.navbar-brand
{
    margin-left: 28px;
}
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdown-menu.show {
    top: 100%;
    left: 0;
    position: absolute;
    background-color: #f1f1f1;
    font-size: 18px;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    z-index: 1;
    text-align: center;
    border-radius: 15px;
    left: 50%!important;
    right: auto!important;
    -webkit-transform: translate(-50%)!important;
    transform: translate(-50%)!important;
    margin-left: auto;
    span{
        border-bottom: 2px solid #9D9D9C;
        padding: 1px 20px;
        color: $c-beige;
        font-family: "gotham-medium";
        font-size: 20px;
}
}
.dropdown:hover .dropdown-menu {
    display: block;
    top: 100%;
    left: 0;
    position: absolute;
    background-color: #f1f1f1;
    font-size: 18px;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    z-index: 1;
    text-align: center;
    border-radius: 15px;
    left: 50%!important;
    right: auto!important;
    -webkit-transform: translate(-50%)!important;
    transform: translate(-50%)!important;
    margin-left: auto;
    font-family: "gotham-medium";
    span{
        border-bottom: 2px solid #9D9D9C;
        padding: 1px 20px;
        color: $c-beige;
        font-family: "gotham-medium";
        font-size: 20px;
    }
a {
    color: #9D9D9C;
    padding: 3px 4px;
    text-decoration: none;
    display: block;
    font-size: 18px;
    text-align: center;
}
}
.dropSpan {
    font-size: 25px;
    cursor: pointer;
    font-family: "gotham-medium";
    color: #f7f7f7;
    margin: 5px 10px 5px 30px;
}

.dropdown-menu.show a {
    color: #9D9D9C;
    padding: 3px 4px;
    text-decoration: none;
    display: block;
}

.dropdown-menu.show a:hover {
    background-color: #f1f1f1;
    border-radius: 15px;
}

.selected {
    background-color: #FFFFFF;
    color: #A28559;
    border-radius: 20px;
    padding: 8px;
    ul{
        display: none !important;
    }
}
.nav-bar__contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 28px;
}
@media only screen and (max-width: 660px) {
    .bg-color {
        height: auto !important;
    }
    
    .nav-bar__contents {
        //display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        margin-bottom: 10px;
    }

    
}


.nav-bar {
    width: 100%;
    height: 100px;
    background-color: $c-lynx-white;
    padding: 0 40px 0 40px;
    //display: flex;
    //align-items: center;
    justify-content: space-between;
    // color: $c-dark-gray;
    background-color: $c-beige;
    color: $c-lynx-white;

    &__title {
        font-family: $avenir-next-weight-700;
        font-size: $text-size-xl;
    }


    &__icon-wrapper {
        position: relative;
    }

    &__profile-picture-img {
        height: 44px;
        width: 44px;
        object-fit: cover;
        border-radius: 999px;
        border: 1px solid $c-weathered-stone;
    }

    &__username {
        font-family: $avenir-next-weight-600;
        font-size: $text-size-body;
        margin-left: 20px;
    }

    &--green-dot {
        height: 9px;
        width: 9px;
        border-radius: 999px;
        background-color: $c-green;
        position: absolute;
        right: 2px;
        top: 2px;
    }

    &__divider {
        border-right: 1px solid $c-dark-gray;
        height: 30px;
        margin-left: 30px;
    }

    &__icon {
        height: 40px;
        object-fit: contain;
        // margin-left: 30px;

        &:hover {
            cursor: pointer;
        }
    }

    &--red-dot {
        height: 6px;
        width: 6px;
        border-radius: 999px;
        background-color: $c-red;
        position: absolute;
        right: 1px;
        top: 2px;
    }
    


    .selected {
        background-color: #FFFFFF;
        color: #A28559;
        border-radius: 20px;
        padding: 8px;
    }

    //.dropdown:hover .dropdown-content {display: block;}

    // .dropdown:hover .dropSpan {
    //     background-color: #f1f1f1;
    //     color: $c-beige;
    // }
}