.input-field-text {
    width: 100%;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    text-align: center;
    // border-radius: 0px 10px 10px 0px;

    &::placeholder {
        color: $c-weathered-stone;
    }

    &:focus {
        outline: none !important;
        border: 2px solid black;
        // border-radius: 0px 10px 10px 0px;
    }
}

.input-field-number {
    width: 100%;
    border: none;
    background-color: white;
    resize: none;
    outline: none;
    text-align: center;
    border-radius: 0px 10px 10px 0px;
    // padding-left: 0px;
    // padding-right: 0px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder {
        color: $c-weathered-stone;
    }

    &:focus {
        outline: none !important;
        border: 2px solid black;
        
        // border-radius: 0px 10px 10px 0px;
    }
}


.input-field-number-2 {
    width: 100%;
    border: none;
    background-color: white;
    resize: none;
    outline: none;
    text-align: center;
    border-radius: 10px 0px 0px 10px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder {
        color: $c-weathered-stone;
    }

    &:focus {
        outline: none !important;
        border: 2px solid black;
        // border-radius: 0px 10px 10px 0px;
    }
}

.input-field-number-3{
    width: 100%;
    border: none;
    background-color: white;
    resize: none;
    outline: none;
    text-align: center;
    border: 2px solid $c-body;
    border-radius: 5px;
    padding:0px;
    font-family: "gotham-bold";
    max-width:80.25px;
    width:80.25px;
    

    &__text-size{
        font-family: "gotham-bold";
        font-size: 10px;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder {
        color: $c-weathered-stone;
    }

    &:focus {
        outline: none !important;
        border: 2px solid black;
        // border-radius: 0px 10px 10px 0px;
    }

}

.input-field-decimal-number {
    width: 100%;
    border: none;
    background-color: white;
    resize: none;
    outline: none;
    text-align: center;
    border-radius: 10px 0px 0px 10px;
 

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder {
        color: $c-weathered-stone;
    }

    &:focus {
        outline: none !important;
        border: 2px solid black;
        // border-radius: 0px 10px 10px 0px;
    }
}




.error{
    color: red;
    font-size: 15px;
    // background-color: aqua;
    text-align: left;
    // margin: 0;
    // padding: 0;
    background-color: transparent;
    width:fit-content;
    height:15px;
    width:310px;
}


.null{
   // width:176px;
    height:15px;
    color:transparent;
    background-color: transparent;
}


select{
   
    border: none;
    background-color: white;
    resize: none;
    outline: none;
    text-align: center;
    border-radius: 0px 10px 10px 0px;

    
    &:focus {
        outline: none !important;
        border: 2px solid black;
        // border-radius: 0px 10px 10px 0px;
    }



}
input.input-checkbox{
    min-width:20px;
    min-height:20px;
    // vertical-align: middle;
    // position: relative;
    // bottom: 2px;
}
