.page-merchant-add {
    height: 100%;
    padding: 5px 15px 0px 15px;
    background-color: #FFFFFF;

    .fixed-content {
        width: 98%;
        position: fixed;
    }

    .top-context {
        height: 90px;
    }

    .title {
        float: left;
        font-size: 60px;
        color: $c-beige;
        font-family: "gotham-bold";
        letter-spacing: 0;
        text-align: left;
    }

    .search-bar {
        width: 400px;
        float: left;
        padding-top: 30px;
        margin-left: 20px;
    }

    .search-button {
        width: 200px;
        float: right;
        padding-right: 10px;
        height: 50px;
        margin-top: 20px;
    }

    .table-layout {
        overflow-y: auto;
        height: 500px;
        border-radius: 15px;
        width: 100%;
    }

    .table-layout::-webkit-scrollbar {
        display: none;
    }

    table {
        width: 100%;
        padding: 5px 15px 0px 15px;
        border-collapse: collapse;
    }

    thead th {
        background-color: #F6F6F6;
        position: sticky;
        top: 0;
    }

    table.center {
        margin-left: auto;
        margin-right: auto;

        th {
            text-decoration: underline;
            font-family: "gotham-bold";
        }

        td {
            font-family: "gotham-book";
        }
    }

    tbody tr:nth-child(odd) {
        background-color: #dddddd;
    }

    tr:nth-child(even) {
        background-color: #F6F6F6;
    }

    th,
    td {
        text-align: center;
        padding: 10px 0px 10px 0px;
        font-size: 18px;
    }

    .alignBottom {
        align-self: flex-end;
    }

    .input-field {
        padding: 40px;
        background-color: #F6F6F6;
        margin: 30px 50px 30px 50px;
    }

    .text-field {
        font-size: 25px;
        font-family: "gotham-bold";
        align-self: flex-end;
    }

    .top-padding {
        div {
            padding-top: 10px;
        }
    }

    .check-box {
        zoom: 1.5;
        transform: scale(1.5);
        -ms-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -o-transform: scale(1.5);
        -moz-transform: scale(1.5);
        transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
    }

    .pagination-button {
        display: inline-block;
        margin-bottom: 10px;
        float: right;

        span {
            font-family: "gotham-bold";
        }

        button {
            text-decoration: none;
            transition: background-color .3;
            border: none;
            background-color: transparent;
        }

        button:hover {
            background-color: #ddd;
        }
    }

    .resetPswButton {
        background-color: #9D9D9C;
        color: white;
        border: none;
        border-radius: 8px;
        font-family: "gotham-bold";
        padding: 3px 5px 3px 5px;
        width: 138px;
        height: 30px
    }

    .search-long-button {
        width: 205px;
        float: right;
        padding-right: 10px;
        height: 50px;
        margin-top: 20px;

        .long-button {
            background-color: $c-orange;
            color: $c-white;
            border: none;
            border-radius: 10px;
            font-size: 18px;
            font-family: "gotham-bold";
            padding: 10px;
        }
    }

    .cards {
        // max-width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-gap: 50px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .card {
        background-color: #F6F6F6;
        color: black;
        // padding: 1rem;
        height: 4rem;
        font-size: 25px;
        font-family: "gotham-bold";
        height: 100%;
        border: none;

        .grid {
            display: grid;
            grid-template-columns: 230px auto;
        }
    }

    .text-area {
        border-radius: 4px;
        border: none;
        outline: none;
        font-family: "avenirNext-medium";
        font-size: 16px;
        color: #323232;
        padding: 10px 13px 8px 13px;
    }

    .text-area-error {
        border-radius: 4px;
        border: 2px solid $c-red !important;
        outline: none;
        font-family: "avenirNext-medium";
        font-size: 16px;
        color: #323232;
        padding: 10px 13px 8px 13px;
    }

    // .upload-file{
    //     visibility: "hidden";
    //     width: 600px
    // }

    .merchant-input {
        position: relative;
        max-width: 420px;

        .input-dropdown {
            background-color: white;
            display: flex;
            flex-direction: column;
            border: 1px solid gray;
        }
        
        .input-dropdown:empty {
            border: none;
        }
        
        .input-dropdown-row {
            cursor: pointer;
            text-align: start;
            margin: 2px 0;
        }
        .input-dropdown-row:hover {
            cursor: pointer;
            background-color: lightgray;
        }

        .merchant-input-text {
            height: 40px;
            border-radius: 4px;
            border: 1px solid $c-border-gray;
            outline: none;
            font-family: $avenir-next-weight-500;
            font-size: 16px;
            color: $c-dark-gray;
            padding: 10px 13px 8px 13px;
            width: 100%;
    
            &::placeholder {
                color: $c-weathered-stone;
            }
    
            &:focus {
                border: 2px solid $c-cyan;
            }

        }

        .merchant-input-text-error {
            height: 40px;
            border-radius: 4px;
            border: 2px solid $c-red !important;
            outline: none;
            font-family: $avenir-next-weight-500;
            font-size: 16px;
            color: $c-dark-gray;
            padding: 10px 13px 8px 13px;
            width: 100%;
    
            &::placeholder {
                color: $c-weathered-stone;
            }
    
            &:focus {
                border: 2px solid $c-cyan;
            }

        }

        .merchant-input-select {
            font-family: $avenir-next-weight-500;
            font-size: 16px;
            // background-color: transparent;
            cursor: pointer;
    
            &__input {
                height: 40px;
                width: 100%;
                border-radius: 4px;
                border: 1px solid $c-border-gray;
                color: $c-dark-gray;
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px 13px 8px 13px;
    
                &--selected {
                    border-radius: 4px 4px 0px 0px;
                    border-top: 2px solid $c-cyan;
                    border-left: 2px solid $c-cyan;
                    border-right: 2px solid $c-cyan;
                    border-bottom: 0px;
                }
    
                &--placeholder {
                    color: $c-weathered-stone;
                }
            }
    
            &__list {
                width: 100%;
                color: $c-gray;
                background: $c-lynx-white;
                border-radius: 0px 0px 4px 4px;
                border-top: 0px;
                border-bottom: 2px solid $c-cyan;
                border-left: 2px solid $c-cyan;
                border-right: 2px solid $c-cyan;
                padding: 0px;
                margin: 0px;
            }
    
            &__option {
                height: 40px;
                padding: 10px 13px 8px 13px;
    
                &:hover,
                &:active {
                    background: $c-cyan;
                    color: $c-white;
                }
            }
        }

        .merchant-input-select-error {
            font-family: $avenir-next-weight-500;
            font-size: 16px;
            // background-color: transparent;
            cursor: pointer;
    
            &__input {
                height: 40px;
                width: 100%;
                border-radius: 4px;
                border: 2px solid $c-red !important;
                color: $c-dark-gray;
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px 13px 8px 13px;
    
                &--selected {
                    border-radius: 4px 4px 0px 0px;
                    border-top: 2px solid $c-cyan;
                    border-left: 2px solid $c-cyan;
                    border-right: 2px solid $c-cyan;
                    border-bottom: 0px;
                }
    
                &--placeholder {
                    color: $c-weathered-stone;
                }
            }
    
            &__list {
                width: 100%;
                color: $c-gray;
                background: $c-lynx-white;
                border-radius: 0px 0px 4px 4px;
                border-top: 0px;
                border-bottom: 2px solid $c-cyan;
                border-left: 2px solid $c-cyan;
                border-right: 2px solid $c-cyan;
                padding: 0px;
                margin: 0px;
            }
    
            &__option {
                height: 40px;
                padding: 10px 13px 8px 13px;
    
                &:hover,
                &:active {
                    background: $c-cyan;
                    color: $c-white;
                }
            }
        }
    }

}