.app-table-footer {
    height: 52px;
    background-color: $c-white;
    box-shadow: -3px 0px 3px 3px $c-tableHeader;
    margin: 0px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__item-show {
        color: $c-gray;
        font-family: $avenir-next-weight-500;
        font-size: 16px;
    }

    .app-table-footer-pagination {
        font-family: $avenir-next-weight-500;
        font-size: 16px;
        color: $c-gray;

        &__input {
            font-family: $avenir-next-weight-500;
            font-size: 16px;
            color: $c-gray;
            width: 24px;
            height: 24px;
            align-items: center;
            text-align: center;
            border: 1px solid #dadada;
            border-radius: 4px;
            margin-left: 5px;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &[type="number"] {
                -moz-appearance: textfield;
            }
        }

        &__arrow-back,
        &__arrow-forward {
            margin-left: 10px;
            cursor: pointer;
        }
    }
}
