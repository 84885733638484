        .page-login {
            width: 100%;
            &__page-name {
                text-align: center;
                font-family: $avenir-next-weight-500;
                font-size: $text-size-mxl;
                color: $c-white;
                padding-bottom: 40px;
                padding-top: 40px;
                @include max-width($scr-lg) {
                    font-size: $text-size-l;
                    padding-bottom: 20px;
                    padding-top: 20px;
                }
            }
            &__input-wrapper {
                width: 100%;
                display: flex;
                justify-content: center;
            }
            &__primary-button {
                margin-bottom: 34px;
                margin-top: 20px;
                &-wrapper {
                    display: flex;
                    justify-content: space-around;
                    &-inline {
                        flex: 1;
                        margin: 0 10px;
                    }
                }
            }
            &__forgot-password {
                margin: 30px 0 40px 0;
                display: flex;
                justify-content: center;
                @include max-width($scr-lg) {
                    font-size: $text-size-l;
                    margin: 10px 0 20px 0;
                }
            }
            &__label-text {
                padding-left: 6%;
                @include max-width(1180px) {
                    padding-left: 270px;
                }
                @include max-width(1024px) {
                    padding-left: 190px;
                }
                @include max-width(821px) {
                    padding-left: 13%;
                }
                @include max-width(769px) {
                    padding-left: 10%;
                }
            }
        }