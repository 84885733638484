.application-view {
    zoom: 80%;
    height: 100%;
    padding: 5px 15px 0px 15px;
    background-color: #ffffff;
    min-width:$min-width;
    .fixed-content {
        width: 98%;
        position: fixed;
    }
    .top-context {
        height: 90px;
        .dropdown {
            position: relative;
            display: inline-block;
        }

        .dropSpan {
            font-size: 25px;
            cursor: pointer;
            font-family: $gotham-weight-400;
        }

        .dropButton {
            background-color: #f5903d;
            color: white;
            border: none;
            border-radius: 10px;
            width: 190px;
            height: 50px;
            font-size: 18px;
            cursor: pointer;
            font-family: $gotham-weight-400;
        }

        .dropButtonHover {
            background-color: white;
            color: #f5903d;
            border: none;
            border-radius: 10px;
            width: 190px;
            height: 50px;
            font-size: 16px;
            cursor: pointer;
            font-family: "gotham-medium";
            text-decoration: underline;
        }

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f1f1f1;
            font-size: 18px;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            text-align: center;
            border-radius: 15px;
            left: 50% !important;
            right: auto !important;
            transform: translate(-50%, 0) !important;
            span {
                border-bottom: 2px solid #9d9d9c;
                padding: 1px 20px;
                color: $c-beige;
                font-family: "gotham-medium";
                font-size: 20px;
            }
        }

        .dropdown-content a {
            color: #9d9d9c;
            padding: 3px 4px;
            text-decoration: none;
            display: block;
        }

        .dropdown-content a:hover {
            background-color: #f1f1f1;
            border-radius: 15px;
        }

        .show {
            display: block;
            top: 8px;
        }
    }
    .title {
        float: left;
        font-size: 60px;
        color: $c-beige;
        font-family: "gotham-bold";
        letter-spacing: 0;
        text-align: left;
    }
    .search-button {
        width: 200px;
        float: right;
        padding-right: 10px;
        height: 50px;
        margin-top: 20px;
    }

    .application-detail {
        padding: 30px 50px 30px 50px;
        .wrapper {
            padding: 0px 20px;
            display: grid;
            background-color: #f6f6f6;
            grid-template-areas:
                "review"
                "ctosScore";
        }
        .review {
            display: flex;
            flex-direction: column;
            grid-area: auto;
            padding: 10px;
            min-width: 600px;
            max-width: 875px;
            .assigned {
                font-family: "gotham-bold";
                color: $c-beige;
                font-size: x-large;
            }
            .reviewing {
                padding-top: 8px;
                font-family: "gotham-book";
                font-size: medium;
                float: right;
            }
            .largeText {
                font-family: "gotham-bold";
                font-size: xx-large;
                color: #9d9d9c;
                float: right;
            }
            hr {
                border-top: 3px solid #9d9d9c;
            }
            .content {
                div {
                    width: 50%;
                    float: left;
                    margin-bottom: 15px;
                }
                span {
                    font-family: "gotham-bold";
                    color: #1d1d1d;
                    font-size: 16px;
                }
                input {
                    border: none;
                    border-radius: 5px;
                    height: 32px;
                    float: right;
                    margin-right: 10px;
                    display: flex;
                    flex: 1;
                    padding-left: 20px;
                }
                // @media (max-width: 1550px) {
                //     input {
                //         width: 150px;
                //     }
                // }
            }
            .supporting-doc {
                display: flex;
                flex-direction: row;
                // margin-top: 12px;
                margin-bottom: 12px;
                &-row {
                    display: flex;
                    flex: 1;
                    flex-direction: row;
                    align-items: center;

                    &-label {
                        font-family: "gotham-bold";
                        color: #1d1d1d;
                        font-size: 16px;
                    }

                    div {
                        flex: 1;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
            .interview-time {
                display: flex;
                flex-direction: row;
                margin-top: 12px;
                margin-bottom: 12px;
                &-row {
                    display: flex;
                    flex: 1;
                    flex-direction: row;

                    &-label {
                        font-family: "gotham-bold";
                        color: #1d1d1d;
                        font-size: 16px;
                    }

                    div {
                        flex: 1;
                    }
                }
            }
            .bottom-content {
                div {
                    margin-bottom: 15px;
                }
                span {
                    font-family: "gotham-bold";
                    color: #1d1d1d;
                    font-size: 16px;
                    width: 25%;
                }
                input {
                    border-radius: 5px;
                    height: 32px;
                    margin-right: 10px;
                    width: 74%;
                    float: right;
                }
                .remark {
                    width: 75.5%;
                    float: right;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    select {
                        width: 88%;
                        border-radius: 5px;
                        height: 32px;
                    }
                    textarea {
                        border-radius: 5px;
                        display: flex;
                        flex: 1;
                        border: none;
                        // height: 32px !important;
                    }
                    button {
                        background-color: $c-orange;
                        color: white;
                        width: 10%;
                        height: 32px;
                        border: none;
                        border-radius: 8px;
                        margin-right: 10px;
                    }
                }
            }

            .briefing-time-content {
                display: flex;
                flex-direction: row;
                margin-top: 12px;
                margin-bottom: 12px;
                div {
                    .briefing-time-label {
                        font-family: "gotham-bold";
                        color: #1d1d1d;
                        font-size: 16px;
                    }
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    div {
                        display: flex;
                        flex: 1;
                    }
                    input {
                        display: flex;
                        flex: 1;
                    }
                }
            }
        }

        .loan-application-view {
            &__score {
                &-wrapper {
                    margin: 40px 10px 40px 10px;
                    border-radius: 10px;
                    min-width: 600px;
                }
                &-button-row {
                    display: flex;
                    flex-direction: row;
                    height: 120px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
                &-button {
                    display: flex;
                    flex: 1;
                    background-color: #a28559;
                    border-radius: 10px;
                    &:hover {
                        cursor: pointer;
                    }
                    &--darker {
                        background-color: #856e49 !important;
                    }
                    &--active {
                        background-color: white !important;
                    }
                }
                &-label-column {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 3;
                    padding-left: 10px;
                }

                &-label-title {
                    color: $c-white;
                    font-size: 34px;
                    font-family: $gotham-weight-400;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    &--active {
                        color: $c-maire !important;
                    }
                }

                &-label-subtitle {
                    font-size: 22px;
                    color: $c-white;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    &--active {
                        color: #9d9d9c !important;
                    }
                }

                &-label-text {
                    &--active {
                        color: white;
                    }
                }

                &-graph {
                    position: relative;
                    padding: 5px;
                    &-wrapper {
                        flex: 1;
                        display: flex;
                        align-items: center;
                    }
                }

                &-point {
                    position: absolute;
                    height: 90%;
                    width: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: $gotham-weight-300;
                    color: $c-white;
                    &--active {
                        color: $c-black;
                    }
                }

                &-content {
                    background-color: $c-white;
                    margin-top: -10px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    &-wrapper {
                        height: 460px;
                        padding: 30px;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }

                    &-row {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 30px;
                        color: $c-lg-gray;
                    }

                    &-column {
                        &-1 {
                            display: flex;
                            flex: 3;
                            font-family: $gotham-weight-400;
                            font-size: $text-size-l;
                        }
                        &-2 {
                            display: flex;
                            flex: 3;
                            font-family: $gotham-weight-200;
                            font-size: $text-size-l;
                        }
                        &-3 {
                            display: flex;
                            flex: 1;
                            font-family: $gotham-weight-200;
                            font-size: $text-size-l;
                        }
                    }
                }

                &-body {
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: column;
                    &:last-child {
                        margin-bottom: none;
                    }
                    &-title {
                        color: $c-lg-gray;
                        font-family: $gotham-weight-400;
                        font-size: $text-size-l;
                    }
                    &-detail {
                        color: $c-lg-gray;
                        font-family: $gotham-weight-200;
                        font-size: 20px;
                    }
                }

                &-ctos-report {
                    color: #437fec;
                    font-size: 20px;
                    font-family: $gotham-weight-200;
                    text-decoration: none;
                }
            }

            &__video {
                &-wrapper {
                    margin-top: 25px;
                    display: none;
                    &--show {
                        display: block;
                    }
                }

                &-none {
                    font-family: $gotham-weight-400;
                }

                &-tab-row {
                    display: flex;
                    flex-direction: row;
                }

                &-tab-button {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: center;
                    background-color: #856e49;
                    font-family: $gotham-weight-400;
                    color: $c-white;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    border-top: 1px solid #856e49;
                    border-left: 1px solid #856e49;
                    border-right: 1px solid #856e49;
                    &--active {
                        background-color: $c-white;
                        color: #856e49;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }

                &-contents {
                    background-color: $c-white;
                    height: 400px;
                    border-bottom: 1px solid $c-beige;
                    border-left: 1px solid $c-beige;
                    border-right: 1px solid $c-beige;
                }

                &-body {
                    &--hide {
                        display: none;
                    }
                    &--show {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                    }
                }
            }

            &__download-wrapper {
                border: 1px solid $c-table-icon;
                padding: 0px 5px;
                border-radius: 6px;
                margin-right: 10px;
                cursor: pointer;
                color: $c-table-icon;
            }
        }

        .ctosScore {
            grid-area: auto;
            .tab {
                overflow: hidden;
                border: 1px solid #ccc;
                background-color: #f1f1f1;
            }

            /* Style the buttons inside the tab */
            .tab button {
                background-color: inherit;
                float: left;
                border: none;
                outline: none;
                cursor: pointer;
                padding: 14px 16px;
                transition: 0.3s;
                font-size: 17px;
            }

            /* Change background color of buttons on hover */
            .tab button:hover {
                background-color: #ddd;
            }

            /* Create an active/current tablink class */
            .tab button.active {
                background-color: #ccc;
            }

            /* Style the tab content */
            .tabcontent {
                display: none;
                padding: 6px 12px;
                border: 1px solid #ccc;
                border-top: none;
            }
        }

        .wrapper {
            grid-template-columns: 50% auto;
            grid-template-areas: "review" "ctosScore";
        }

        // @media (max-width: 1253px) {
        //     .review .largeText {
        //         font-family: "gotham-bold";
        //         font-size: large;
        //         color: #9d9d9c;
        //         float: right;
        //     }
        //     .review .select-field {
        //         margin-top: 8px;
        //         border: #e3e3e3 1px solid;
        //         border-radius: 8px;
        //         width: 200px;
        //         height: 30px;
        //         text-align: center;
        //     }
        //     .review .bottom-content span {
        //         width: 100%;
        //     }
        //     .review .bottom-content input {
        //         width: 100%;
        //     }
        //     .review .bottom-content .remark {
        //         width: 100%;
        //     }
        //     .review .bottom-content .remark select {
        //         float: left;
        //         width: 100%;
        //     }
        // }

        .select-field {
            margin-top: 8px;
            border: #e3e3e3 1px solid;
            border-radius: 8px;
            width: 250px;
            height: 37px;
            text-align: center;
        }
    }

    .dropdown-reject {
        font-family: $gotham-weight-400;
        color: $c-orange;
        list-style-type: none;
        cursor: pointer;
        margin: 0px;
        padding: 0px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-divider-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-divider {
        border-bottom: 1px solid $c-table-icon;
        width: 80%;
        margin: 0;
        padding: 0;
    }

    .dropdown-selection {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        padding: 8px 0px;
        cursor: pointer;
        &:hover {
            color: $c-orange;
        }
    }
}

// .audit-trail-modal {
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     &__content {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         width: 850px;
//         height: 550px;
//     }

//     &__body {
//         width: 100%;
//         padding: 20px 30px;
//     }

//     &__button-wrapper {
//         width: 280px;
//         height: 50px;
//         margin-bottom: 15px;
//     }

//     &__table-body {
//         max-height: 380px;
//         overflow-y: scroll;
//         overflow-x: hidden;
//     }

//     &__table-body::-webkit-scrollbar {
//         // display: none;
//         width: 10px;
//     }
//     &__table-body::-webkit-scrollbar-track {
//         background: #f1f1f1;
//         border-radius: 8px;
//     }
//     &__table-body::-webkit-scrollbar-thumb {
//         background: #888;
//         border-radius: 8px;
//     }

//     &__header-row {
//         display: flex;
//         width: 100%;
//         text-decoration: underline;
//         font-size: 20px;
//         font-family: $gotham-weight-400;
//         padding: 0px 20px;
//         height: 60px;
//         align-items: center;
//     }

//     &__table-row {
//         display: flex;
//         flex-direction: row;
//         font-size: 20px;
//         color: $c-maire;
//         min-height: 60px;
//         align-items: center;
//         padding: 0px 20px;
//     }

//     &__table-row--gray {
//         background-color: $c-light-gray;
//     }

//     &__column-timestamp {
//         width: 270px;
//     }

//     &__column-details {
//         width: 340px;
//     }

//     &__column-by {
//         width: 200px;
//     }

// }

.audit-trail-modal {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 850px;
        height: 550px;
        width: 60% !important;
    }

    &__body {
        width: 100%;
        padding: 20px 30px;
    }

    &__button-wrapper {
        width: 280px;
        height: 50px;
        margin-bottom: 15px;
    }

    &__table-body {
        max-height: 380px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    &__header-row {
        display: flex;
        width: 100%;
        text-decoration: underline;
        font-size: 20px;
        font-family: $gotham-weight-400;
        padding: 0px 20px;
        height: 60px;
        align-items: center;
    }

    &__table-row {
        display: flex;
        flex-direction: row;
        font-size: 20px;
        color: $c-maire;
        min-height: 60px;
        align-items: center;
        padding: 0px 20px;
    }

    &__table-row--gray {
        background-color: $c-light-gray;
    }

    &__column-timestamp {
        width: 270px;
    }

    &__column-details {
        width: 340px;
    }

    &__column-by {
        width: 200px;
    }
}

.pop-up {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pop-up-data {
    position: center;
    // width: 52% !important;
    background-color: white;
    border-radius: 30px;
    text-align: center;
    font-family: "gotham-bold";
    font-size: 25px;

    padding: 30px 30px 10px 30px;
  }

  .pop-up-message {
    font-family: "gotham-bold";
    font-size: 30px;
    color: $c-beige;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pop-up-button {
    border: none;
    border-radius: 5px;
    background-color: $c-orange;
    color: $c-white;
    padding: 5px;
    width: 250px;
  }
  .pop-up-close-button {
    border: none;
    border-radius: 5px;
    background-color: $c-red;
    color: $c-white;
    padding: 5px;
    width: 250px;
  }
