.app-table-page {
    min-height: calc(125% - 52px);

    &__wrapper {
        padding: 30px 40px 30px 40px;
    }

    &__modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border: 1px solid #ccc;
        background: #fff;
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
    }

    &__modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 999999;
    }

    &__title {
        font-family: $avenir-next-weight-700;
        color: $c-blue;
        font-size: $text-size-mxl;
        margin-top: 25px;
        margin-bottom: 20px;
    }

    &__row-selection-list {
        padding-bottom: 10px;
        border-bottom: 1px solid $c-border-gray;
    }

    &__searchbar-wrapper {
        margin: 30px 0px 30px 0px;
        width: 633px;

        @include max-width($scr-md) {
            width: 100%;
        }
    }

    .table-function-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 24px;
        align-items: flex-end;

        @include max-width($scr-sm) {
            flex-direction: column;
        }

        &__total-result {
            font-family: $avenir-next-weight-500;
            font-size: $text-size-body;
        }

        &__buttons-list {
            flex-direction: row;
            display: flex;
            position: relative;
        }

        &__button-wrapper {
            margin-left: 30px;
        }

        &__view-columns-button {
            font-family: $avenir-next-weight-600;
            font-size: $text-size-body;
            color: $c-cyan;
            margin-left: 30px;

            &:hover {
                cursor: pointer;
            }
        }

        &__view-columns-dropdown-list {
            width: 240px;
            position: absolute;
            z-index: 999;
            font-family: $avenir-next-weight-500;
            font-size: $text-size-body;
            right: 0;
            top: 30px;
            box-shadow: 0 5px 5px 0 $c-tableHeader;
            background-color: $c-lynx-white;

            .view-columns-dropdown-list {
                &__reset-button {
                    width: 100%;
                    background-color: transparent;
                    font-family: $avenir-next-weight-600;
                    color: $c-black;
                    display: flex;
                    align-items: center;
                    height: 40px;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    border-bottom: 1px solid $c-border-gray;
                }

                &__reset-image {
                    height: 16px;
                    width: 16px;
                    object-fit: contain;
                    margin-left: 15px;
                    margin-right: 15px;
                }
                &__selection {
                    width: "100%";
                    height: 40px;
                    display: flex;
                    align-items: center;
                    display: flex;
                    flex-direction: row;

                    &:hover {
                        cursor: pointer;
                        background-color: $c-cyan;

                        input[type="checkbox"]:checked + label::after {
                            content: "";
                            position: absolute;
                            width: 2ex;
                            height: 1ex;
                            background: rgba(0, 0, 0, 0);
                            top: 1.9ex;
                            border: 2px solid $c-white;
                            border-top: none;
                            border-right: none;
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                            margin-left: 10px;
                            margin-top: -3px;
                        }
                    }

                    input[type="checkbox"]:checked + label::after {
                        content: "";
                        position: absolute;
                        width: 2ex;
                        height: 1ex;
                        background: rgba(0, 0, 0, 0);
                        top: 1.9ex;
                        border: 2px solid $c-cyan;
                        border-top: none;
                        border-right: none;
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                        margin-left: 10px;
                        margin-top: -3px;
                    }

                    // hide ori checkbox
                    input[type="checkbox"] {
                        position: absolute;
                        left: -999em;
                        opacity: 0;
                        height: 0;
                        width: 0;
                    }

                    input[type="checkbox"] + label {
                        position: relative;
                        overflow: hidden;
                        cursor: pointer;
                    }

                    input[type="checkbox"] + label::before {
                        content: "";
                        display: inline-block;
                        margin-right: 30px;
                        margin-left: 10px;
                    }

                    input:checked + label {
                        color: $c-cyan;
                    }
                }

                &__text {
                    margin-left: 13.5px;
                    width: 100%;
                    height: 100%;
                    margin-top: 8px;
                    display: flex;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                        color: $c-white !important;
                    }
                }
            }
        }
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    &--after-open {
        opacity: 1;
    }

    &--before-close {
        opacity: 0;
    }
}
