.app-side-nav {
    display: none;
    padding: 11px 0;
    width: 325px;
    min-width: 50px;
    background-color: $c-dark-blue;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    &--shrink {
        animation: shrinkNav 0.5s ease forwards;
    }
    &--expand {
        animation: expandNav 0.5s ease forwards;
    }

    &__action {
        width: 100%;
        min-height: 50px;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        svg {
            width: 20px;
            color: $c-white;
        }
    }

    &__lists {
        width: 100%;
        padding: 0;

        &--shrink {
            width: 100%;

            li {
                p {
                    animation: hideText 0.2s ease forwards;
                }
            }
        }

        &--expand {
            li {
                p {
                    animation: showText 2s ease forwards;
                }
            }
        }
    }

    &__link {
        width: 100%;
        display: block;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &__icon {
        g {
            fill: $c-grayish-blue;
            stroke: $c-grayish-blue;
        }

        path {
            fill: $c-grayish-blue;
            stroke: $c-grayish-blue;
        }

        circle {
            stroke: $c-grayish-blue;
        }

        &--active {
            g {
                fill: $c-light-orange;
                stroke: $c-light-orange;
            }

            path {
                fill: $c-light-orange;
                stroke: $c-light-orange;
            }

            circle {
                stroke: $c-light-orange;
            }
        }
    }

    &__item {
        width: 100%;
        padding: 15px;
        list-style-type: none;
        display: flex;
        color: $c-grayish-blue;

        &--active {
            color: $c-light-orange;

            img {
                filter: brightness(0) invert(1);
            }
        }

        &:hover {
            background-color: rgba($c-white, 0.1);

            img {
                filter: brightness(0) invert(1);
            }
        }
    }

    &__text {
        margin: 0;
        margin-left: 10px;
        opacity: 1;
        font-family: $avenir-next-weight-600;
        font-size: $text-size-body;
        white-space: nowrap;
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    &__caret-dropdown {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        color: $c-grayish-blue;

        &--active {
            color: $c-light-orange;
        }
    }

    &__subs-list {
        padding: 0;
        cursor: pointer;

        &--active {
            .app-side-nav__caret-dropdown {
                transform: rotate(180deg);
            }
        }
    }

    &__subs-item-dot {
        height: 20px;
        width: 20px;
        color: $c-grayish-blue;
    }

    &__subs-item {
        display: none;
        color: $c-grayish-blue;
        cursor: pointer;
        padding: 15px;
        list-style-type: circle;

        &--active {
            display: flex;
            flex-direction: row;
        }

        &--selected {
            color: $c-primary;
            background-color: $c-light-orange;

            .app-side-nav__subs-item-dot {
                color: $c-primary;
            }
        }

        &:hover {
            background-color: rgba($c-white, 0.1);

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

@keyframes shrinkNav {
    0% {
        width: 325px;
    }
    100% {
        width: 50px;
    }
}

@keyframes expandNav {
    0% {
        width: 50px;
    }
    100% {
        width: 325px;
    }
}

@keyframes showText {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes hideText {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
