.modal {
  .modal-dialog {
    max-width: 100%;
    max-height: 110%;
  }
  .select-time {
    position: fixed;
    // z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
   
   
    &__content {
      min-width: 80%;
      position: center;
      padding: 10px 63px 10px 63px;
      width: 80%;
      background-color: white;
      border-radius: 30px;
      text-align: center;
      font-family: "gotham-bold";
      font-size: 25px;
    }

    .header {
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      border-bottom: 0;

      .modal-title {
        line-height: 0;
        margin: 0px;
      }

      &__close-button {
        width: 20px;
        height: 20px;
        cursor: pointer;
        visibility: hidden;
      }
    }

    .body {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__title {
        font-family: $avenir-next-weight-600;
        font-size: 26px;
        color: $c-dark-gray;
        margin-bottom: 20px;
      }

      &__description {
        font-family: $avenir-next-weight-500;
        font-size: 16px;
        color: $c-dark-gray;
        margin-bottom: 34px;
      }

      &__message {
        font-family: "gotham-bold";
        font-size: 30px;
        color: $c-beige;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &__schedule {
        // display: flex;
        padding: 10px;
        // width: 1000px;
        font-family: "gotham-bold";
        .time {
          // // max-width: 220px;
          // border: 1px solid #1D1D1B;
          // // justify-content: space-between;
          // margin-right: 10px;
          // cursor: pointer;
          // // width: 500px;
          // // justify-content: center;
          border: 1px solid black;
          min-width: 160px;
          max-width: 160px;
          border-radius: 4.49px;
          cursor: pointer;
          justify-content: space-between;
          
        }
        .active {
          border: 1px solid #f5903d;
          color: #f5903d;
        }
        .time:focus {
          border: 1px solid #f5903d;
          color: #f5903d;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // padding-top: 29px;

      > * {
        margin: 0px 15px 0px 15px;
      }

      &__left {
        font-family: $avenir-next-weight-500;
        font-size: 16px;
        // color: $c-gray;
        cursor: pointer;
        background-color: #f5903d;
        color: white;
      }

      &__right {
        font-family: $avenir-next-weight-500;
        font-size: 16px;
        color: $c-cyan;
        cursor: pointer;
      }
      &__centerButton {
        font-family: "gotham-bold";
        background-color: #f5903d;
        color: white;
        width: 280px;
        height: 50px;
        border: none;
        border-radius: 10px;
      }
    }
  }
}
