.app-input {
    position: relative;
    max-width: 420px;

    .input-dropdown {
        background-color: white;
        display: flex;
        flex-direction: column;
        border: 1px solid gray;
    }
    
    .input-dropdown:empty {
        border: none;
    }
    
    .input-dropdown-row {
        cursor: pointer;
        text-align: start;
        margin: 2px 0;
    }
    .input-dropdown-row:hover {
        cursor: pointer;
        background-color: lightgray;
    }

    .app-input-text {
        height: 40px;
        border-radius: 4px;
        border: 1px solid $c-border-gray;
        outline: none;
        font-family: $avenir-next-weight-500;
        font-size: 16px;
        color: $c-dark-gray;
        padding: 10px 13px 8px 13px;
        width: 100%;

        &::placeholder {
            color: $c-weathered-stone;
        }

        &:focus {
            border: 2px solid $c-cyan;
        }
    }

    .app-input-text-error {
        height: 40px;
        border-radius: 4px;
        border: 1px solid red;
        outline: none;
        font-family: $avenir-next-weight-500;
        font-size: 16px;
        color: $c-dark-gray;
        padding: 10px 13px 8px 13px;
        width: 100%;

        &::placeholder {
            color: $c-weathered-stone;
        }

        &:focus {
            border: 2px solid $c-cyan;
        }
    }

    .app-input-select {
        font-family: $avenir-next-weight-500;
        font-size: 16px;
        // background-color: transparent;
        cursor: pointer;

        &__input {
            height: 40px;
            width: 100%;
            border-radius: 4px;
            border: 1px solid $c-border-gray;
            color: $c-dark-gray;
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px 13px 8px 13px;

            &--selected {
                border-radius: 4px 4px 0px 0px;
                border-top: 2px solid $c-cyan;
                border-left: 2px solid $c-cyan;
                border-right: 2px solid $c-cyan;
                border-bottom: 0px;
            }

            &--placeholder {
                color: $c-weathered-stone;
            }
        }

        &__list {
            width: 100%;
            color: $c-gray;
            background: $c-lynx-white;
            border-radius: 0px 0px 4px 4px;
            border-top: 0px;
            border-bottom: 2px solid $c-cyan;
            border-left: 2px solid $c-cyan;
            border-right: 2px solid $c-cyan;
            padding: 0px;
            margin: 0px;
        }

        &__option {
            height: 40px;
            padding: 10px 13px 8px 13px;

            &:hover,
            &:active {
                background: $c-cyan;
                color: $c-white;
            }
        }
    }

    .app-input-select-error {
        font-family: $avenir-next-weight-500;
        font-size: 16px;
        // background-color: transparent;
        cursor: pointer;

        &__input {
            height: 40px;
            width: 100%;
            border-radius: 4px;
            border: 1px solid red;
            color: $c-dark-gray;
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px 13px 8px 13px;

            &--selected {
                border-radius: 4px 4px 0px 0px;
                border-top: 2px solid $c-cyan;
                border-left: 2px solid $c-cyan;
                border-right: 2px solid $c-cyan;
                border-bottom: 0px;
            }

            &--placeholder {
                color: $c-weathered-stone;
            }
        }

        &__list {
            width: 100%;
            color: $c-gray;
            background: $c-lynx-white;
            border-radius: 0px 0px 4px 4px;
            border-top: 0px;
            border-bottom: 2px solid $c-cyan;
            border-left: 2px solid $c-cyan;
            border-right: 2px solid $c-cyan;
            padding: 0px;
            margin: 0px;
        }

        &__option {
            height: 40px;
            padding: 10px 13px 8px 13px;

            &:hover,
            &:active {
                background: $c-cyan;
                color: $c-white;
            }
        }
    }

    .app-input-number {
        display: inline-block;
        position: relative;
        width: 100%;

        &__input {
            height: 40px;
            border-radius: 4px;
            border: 1px solid $c-border-gray;
            outline: none;
            font-family: $avenir-next-weight-500;
            font-size: 16px;
            color: $c-dark-gray;
            padding: 10px 13px 8px 13px;
            width: 100%;

            &::placeholder {
                font-family: $avenir-next-weight-500;
                font-size: 16px;
                color: $c-weathered-stone;
            }

            &:focus {
                border: 2px solid $c-cyan;
            }
        }

        &--with-units {
            &::after {
                position: absolute;
                top: 9px;
                right: 19px;
                display: flex;
                align-items: center;
                transition: all 0.05s ease-in-out;
                font-family: $avenir-next-weight-500;
                font-size: 16px;
                color: $c-dark-gray;
                // padding: 9px 41px 8px 9px;
                // border-left: 1px solid $c-border-gray;
            }

            &:hover::after,
            &:focus-within::after {
                right: 35px;
            }

            &::after {
                content: "units";
            }
        }
    }

    .app-input-date {
        position: relative;

        &__input {
            height: 40px;
            border-radius: 4px;
            border: 1px solid $c-border-gray;
            outline: none;
            font-family: $avenir-next-weight-500;
            font-size: 16px;
            color: $c-dark-gray;
            padding: 10px 13px 8px 13px;
            width: 100%;

            &::placeholder {
                color: $c-weathered-stone;
            }

            &:focus {
                border: 2px solid $c-cyan;
            }
        }

        &__icon {
            position: absolute;
            top: 9px;
            right: 19px;
            cursor: pointer;
        }

        // react-datepicker class
        .react-datepicker__tab-loop {
            filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.1));

            .react-datepicker-popper {
                padding-top: 5px;
            }

            .react-datepicker__triangle {
                display: none;
            }
        }
    }
    .app-input-icon{
        position: absolute;
        top: 7px;
        right: 190px;
       
    }

    .app-input-icon-without-reset-button{
        position: absolute;
        top: 7px;
        right: 12px;
       
    }

    .app-input-password {
        position: absolute;
        top: 7px;
        right: 12px
    }
}
