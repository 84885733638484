.page-calander-settings {
    zoom: 85%;
    // height: calc(100% - 100px);
    padding: 5px 15px 0px 15px;
    background-color: #FFFFFF;
    // min-width: $min-width;
    .top-context {
        height: 90px;
    }
    .title {
        float: left;
        font-size: 60px;
        color: $c-beige;
        font-family: "gotham-bold";
        letter-spacing: 0;
        text-align: left;
    }
    .search-button {
        width: 200px;
        float: right;
        padding-right: 10px;
        height: 50px;
        margin-top: 20px;
    }
    .body-context {
        background-color: $c-light-gray;
        height: 100%;
        margin: 40px;
        &__container {
            display: flex;
            flex-direction: column;
            margin: 40px;
        }
    }
    .fields {
        width: 100%;
        overflow: hidden;
        // padding: 40px 10px 10px 50px;
        .text-field {
            width: 20%;
            float: left;
            font-size: 25px;
            font-family: "gotham-bold";
            height: 46px;
            display: flex;
            align-items: center;
        }
        .input-field {
            input{
                width: 55%;
                border: none;
                border-radius: 10px;
                height: 46px;
                background: $c-white 0% 0% no-repeat padding-box;
            }
            button{
                background: $c-nenon-green 0% 0% no-repeat padding-box;
                color: $c-white;
                border: none;
                border-radius: 10px;
                height: 46px;
                width: 250px;
                font-size: 22px;
                font-family: "gotham-bold";
            }
        }
        .row-field {
            padding-top: 20px;
            span {
                width: 100px;
                font-size: 25px;
                font-family: "gotham-bold";
                min-height: 46px;
                align-items: center;
                margin-right: 100px;
                float: left;
                padding:8px 0;
            }
            div {
                width: 60% ;
                border: none;
                border-radius: 10px;
                min-height: 46px;
                overflow: hidden;
                text-overflow: ellipsis; 
                white-space: nowrap; 
                padding:8px 5px;
                background: $c-white 0% 0% no-repeat padding-box;
                font-family: "gotham-light";
                font-size:20px ;
                
            }
            button {
                background: $c-nenon-green 0% 0% no-repeat padding-box;
                color: $c-white;
                border: none;
                border-radius: 10px;
                height: 46px;
                width: 250px;
                font-size: 25px;
                font-family: "gotham-bold";
            }
           
            .input-number {
                width: 140px;
                border: none;
                border-radius: 10px;
                height: 46px;
                background: $c-white 0% 0% no-repeat padding-box;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            
            }
            .label-right {
                color: $c-orange;
                float: right;
            }
        }

        .bottom-field {
            padding-top: 20px;
            div {
                width: 380px;
                font-size: 25px;
                font-family: "gotham-bold";
                height: 46px;
                align-items: center;
                float: left;
                padding: 5px 0;
            }
            .input-number {
                width: 140px;
                border: none;
                border-radius: 10px;
                height: 46px;
                background: $c-white 0% 0% no-repeat padding-box;
                text-align: center;
                font-family: "gotham-light" !important;
                font-size:20px ;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                
            }
        }
    }
    // .bottom-field {
    //     padding: 40px 60px 10px 10px;
    //     .bottom-row-field {
    //         padding-top: 20px;
    //         padding-left: 40px;
    //         span {
    //             width: 380px;
    //             font-size: 25px;
    //             font-family: "gotham-bold";
    //             height: 46px;
    //             align-items: center;
    //             margin-right: 100px;
    //             float: left;
    //         }
    //         input {
    //             width: 200px;
    //             border: none;
    //             border-radius: 10px;
    //             height: 46px;
    //             background: $c-white 0% 0% no-repeat padding-box;
    //         }
    //     }
    // }
}